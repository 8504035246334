export const selectActiveScripts = (state, id, type = 'flow') => {
  const { scripts: obj } = state.scripts
  // const project = state?.scripts?.projects?.id

  if (!obj) return []

  const entries = Object.entries(obj)

  const arr = []

  entries.forEach((ele) => {
    if (ele[1]?.project_id === id && ele[1].type === type) arr.push(ele[1])
  })
  return arr
}

export const selectActiveAndDeletedScripts = (state, id) => {
  const { scripts: obj } = state.scripts
  if (!obj) return []

  const deletedScripts = []
  const activeScripts = []
  const pausedScripts = []
  const data = state?.scripts?.projects?.[id]

  data?.Active?.forEach((element) => {
    activeScripts.push(obj[element])
  })
  data?.Delete?.forEach((element) => {
    deletedScripts.push(obj[element])
  })
  data?.Pause?.forEach((element) => {
    pausedScripts.push(obj[element])
  })

  return {
    deletedScripts: deletedScripts.sort((a, b) => b.updatedAt?.localeCompare(a.updatedAt)) || [],
    activeScripts: activeScripts.sort((a, b) => b.updatedAt?.localeCompare(a.updatedAt)) || [],
    pausedScripts: pausedScripts.sort((a, b) => b.updatedAt?.localeCompare(a.updatedAt)) || []
  }
}

export const selectOneScript = (state, id) => {
  const { scripts: obj } = state.scripts
  if (!obj) return null
  return obj[id] || null
}

export const getAllData = (state, id) => {
  let obj = {}
  const { scripts } = state.scripts
  obj = { ...obj, user_id: state.user.userId }
  const scriptData = scripts[id]
  obj = { ...obj, org_id: scriptData?.org_id, project_id: scriptData?.project_id, script_id: id }
  return obj
}

export const getAppIntegrationIds = (state, projectId = null) => {
  const projects = state.scripts.projects

  const processAppIntegration = (appIntegration, integratedApps) => {
    Object.keys(appIntegration || {}).forEach((appId) => {
      const appData = appIntegration[appId]

      const mergeAndUnique = (arr1, arr2) => [...new Set([...arr1, ...arr2])]
      // Initialize the arrays
      const active = mergeAndUnique(appData.usedAsTrigger?.active || [], appData.usedAsAction?.active || [])
      const deleteArr = mergeAndUnique(appData.usedAsTrigger?.delete || [], appData.usedAsAction?.delete || [])
      const pause = mergeAndUnique(appData.usedAsTrigger?.pause || [], appData.usedAsAction?.pause || [])

      // Only add to integratedApps if at least one array is non-empty
      integratedApps[appId] = {
        active,
        delete: deleteArr,
        pause
      }
    })
  }
  if (projectId && projects[projectId]) {
    // If projectId is provided and exists, get services for that project
    const integratedApps = {}
    processAppIntegration(projects[projectId].appIntegration, integratedApps)
    if (projects[projectId].webhook?.['active']?.length) {
      integratedApps['webhook'] = { active: [] }
      integratedApps['webhook'].active = projects[projectId].webhook?.['active'] || []
    }

    return integratedApps
  }

  // If projectId doesn't exist or isn't provided, group all services together
  const integratedApps = {}

  Object.keys(projects).forEach((id) => {
    processAppIntegration(projects?.[id]?.appIntegration, integratedApps)
  })

  return integratedApps
}
export const calculateEnabledServiceObject = (state, projectId, integrationServiceId, permitsingleflowJson) => {
  const enabledFlows =
    integrationServiceId === 'webhook'
      ? state.scripts.projects?.[projectId]?.webhook || { active: [] }
      : state.scripts.projects?.[projectId]?.appIntegration?.[integrationServiceId] || {
          usedAsTrigger: { active: [] },
          usedAsAction: { active: [] }
        }

  const enabledScriptList =
    integrationServiceId === 'webhook'
      ? enabledFlows?.active || []
      : [...(enabledFlows?.usedAsTrigger?.active || []), ...(enabledFlows?.usedAsAction?.active || [])]

  const triggerList = Object.values(state.scripts.scripts)
    .filter((script) => (!projectId || script.project_id === projectId) && enabledScriptList.includes(script.id))
    .reduce((acc, script) => {
      acc[script.id] = script?.json_script?.trigger
      return acc
    }, {})

  const updatedServiceObject = { enabledTriggers: {} }

  Object.values(triggerList).forEach((trigger) => {
    const triggerEventId = trigger?.selectedCreate || 'webhook'
    updatedServiceObject.enabledTriggers[triggerEventId] = permitsingleflowJson?.[triggerEventId]
  })
  return updatedServiceObject
}
