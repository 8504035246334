import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { TextField, Typography, Box, Container } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import CustomModal from '../../components/customModal/customModal'
import { useFetchPlugins } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import SelectOrgModal from './selectOrgModal.tsx'
import { ServicePageSkeleton } from '../../components/skeleton/servicePageSkeleton'

export function ServicePage() {
  const navigate = useNavigate()
  const location = useLocation()
  const [queryParamss, setQueryParams] = useState(new URLSearchParams(location.search))

  useEffect(() => {
    setQueryParams(new URLSearchParams(window.location.search))
  }, [window.location.search])

  useEffect(() => {
    const handlePopState = () => {
      setQueryParams(new URLSearchParams(window.location.search))
    }
    // Listen for the popstate event to handle navigation changes
    window.addEventListener('popstate', handlePopState)
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  const queryParams: { [key: string]: any } = {}
  queryParamss.forEach((value, key) => {
    queryParams[key] = value
  })

  const [searchPlugin, setSearchPlugin] = useState('')
  const [showOrgPopup, setShowOrgPopup] = useState(!queryParams['orgid'])
  const searchPlug = queryParams['searchplug']

  useEffect(() => {
    if (queryParams['searchplug']) setSearchPlugin(searchPlug || '')
    setShowOrgPopup(!queryParams['orgid'])
  }, [queryParamss])

  const { data, isLoading } = useFetchPlugins(queryParams.orgid)
  const allPluginsList = data || {}
  const filteredPlugins = Object?.values(allPluginsList)?.filter((plugin) => {
    const searchTerms = searchPlugin?.split(',').map((term) => term.trim().toLowerCase())
    return searchTerms?.some((term) => plugin?.name?.toLowerCase()?.includes(term) || plugin?.rowid?.toLowerCase()?.includes(term))
  })

  const setPluginDetails = async (object) => {
    let url = `/auth/service/${object.rowid}`

    const { search } = window.location
    if (search) {
      url += search
    }

    navigate(url, {
      state: {
        pluginDetails: object
      }
    })
  }

  const searchService = (e) => {
    setSearchPlugin(e.target.value)
  }
  return (
    <div className='p-4 w-100'>
      {queryParams?.['orgid'] && (
        <Container maxWidth='md' className='flex-col gap-3'>
          <Box className='flex-col gap-5'>
            <Typography variant='h4'>Add New Connection </Typography>
            <TextField
              size='small'
              width='small'
              id='services'
              name='services'
              value={searchPlugin}
              type='text'
              label='Search services'
              variant='outlined'
              onChange={searchService}
              autoFocus
            />
          </Box>
          <Box className='flex flex-wrap w-100 h-100'>
            {filteredPlugins?.length === 0 && !isLoading && <Typography variant='h3'>No data found</Typography>}
            {isLoading && (
              <Box className='w-100 h-100'>
                <ServicePageSkeleton />
              </Box>
            )}
            {filteredPlugins?.map((object) => (
              <Box
                className='p-2 flex-center cursor-pointer authdatabox mb-3 mr-3'
                key={object.rowid}
                onClick={() => setPluginDetails(object)}
              >
                <Avatar alt={object?.name} src={object?.iconurl} variant='extrasmall' className='mr-3' />
                <Typography className='mr-3'>{object?.name}</Typography>
                <ArrowForwardIosIcon className='extra-small-icon' />
              </Box>
            ))}
          </Box>
        </Container>
      )}
      <CustomModal openModal={showOrgPopup}>
        <SelectOrgModal setShowOrgPopup={setShowOrgPopup} />
      </CustomModal>
    </div>
  )
}
