import { Box, Button, Card, Chip, Link, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ALERTTYPES, MiscTypes, ParamsEnums } from '../../enums'
import { useAllAuthenticatedPlugins } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { generateRandomString, isColorVisible } from '../../utils/utilities'
import IconWrapper from '../../components/IconWrapper/IconWrapper.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import config from '../../config'
import selectActiveUser from '../../store/user/userSelector'
import { errorToast } from '../../components/customToast'
import { storeAuthDetailsToRedis } from '../../api/index'
import { sendAlert } from '../../utils/alertUtility.ts'

type AuthenticationsforSpecificplugProps = {
  orgId: string
  selectedService: {
    rowid: string
  }
  projectId: string
  scriptId: string
}

/**
 * AuthenticationsforSpecificplug component displays authentications for a specific plugin.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.orgId - The organization ID
 * @param {Object} props.selectedService - The selected service object
 * @param {string} props.selectedService.rowid - The row ID of the selected service
 * @param {string} props.projectId - The project ID
 * @param {string} props.scriptId - The script ID
 *
 * @returns {JSX.Element} The rendered AuthenticationsforSpecificplug component
 *
 * @description
 * This component fetches and displays authentication data for a specific plugin.
 * It allows users to view and manage authentications, open authentication popups,
 * and handles real-time updates through message event listeners.
 */

function AuthenticationsforSpecificplug({ orgId, selectedService, projectId, scriptId }: AuthenticationsforSpecificplugProps) {
  const { orgUsers, userData, orgName, mode, embedProjectName } = useCustomSelector((state) => ({
    orgUsers: state?.orgUsers?.data,
    userData: selectActiveUser(state),
    orgName: state?.orgs?.orgs?.[orgId]?.name,
    mode: state?.appInfo?.mode,
    embedProjectName: state?.projects?.embedProject?.[orgId]?.active?.[projectId]?.title
  }))

  const allAuthData = useAllAuthenticatedPlugins(orgId)?.data
  const [showLess, setShowLess] = useState(true)
  const [authData, setAuthData] = useState(allAuthData)

  useEffect(() => {
    setAuthData(allAuthData)
  }, allAuthData)

  function messageEventListener(event) {
    if (event.data.payload) return
    if (event.data.error) {
      errorToast(event?.data?.error)
      return
    }
    const objToAdd = event.data
    objToAdd.service_id = selectedService?.rowid
    objToAdd.user_id = userData?.userId
    const newAuthData = [...authData, objToAdd] // Create a new array
    setAuthData(newAuthData)
  }

  const openPopup = (row, update) => {
    let key
    if (mode === MiscTypes.EMBED_MODE) {
      try {
        key = generateRandomString()
        storeAuthDetailsToRedis({ token: sessionStorage.getItem('ssoEmbedToken') }, key)
      } catch (error) {
        sendAlert(error, ALERTTYPES.FRONTEND, 'storeAuthDetailsToRedis api alert')
      }
    }
    const popUpWindowUrl = row.service_id
      ? `${process.env.REACT_APP_AUTH_URL}/auth/service/${row.service_id}`
      : `${process.env.REACT_APP_AUTH_URL}${config.servicePage}`
    const params = {
      userid: userData?.userId,
      orgid: orgId,
      orgId: orgId,
      projectid: projectId,
      scriptid: scriptId,
      authidtoupdatetoken: row.id,
      level: mode === MiscTypes.EMBED_MODE ? 'project' : 'org',
      mode,
      _key: key,
      orgOrProjectName: mode === MiscTypes.EMBED_MODE ? embedProjectName : orgName,
      serviceid: row.service_id,
      authrowid: row.auth_row_id,
      isUpdate: update
    }

    const queryParams = Object.entries(params)
      .filter(([, value]) => value !== '' && value !== undefined) // Exclude empty strings and undefined values
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
    const targetUrl = `${popUpWindowUrl}?${queryParams}`

    const popupWindow = window.open(targetUrl, 'PopupPage', 'height=600,width=800')
    window.addEventListener('message', messageEventListener)

    const checkWindowClosed = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
        window.removeEventListener('message', messageEventListener)
        clearInterval(checkWindowClosed)
      }
    }, 100)
  }

  return authData?.filter((row) => row.service_id === selectedService?.rowid)?.length ? (
    <Box className='column w-100 gap-1'>
      <Typography variant='smallHeading'>Connections</Typography>
      {authData
        ?.filter((row) => row.service_id === selectedService?.rowid)
        ?.slice(0, showLess ? 4 : undefined)
        ?.map((auth) => {
          const updatedAtDate = new Date(auth.updated_at)
          const updatedAt = auth.updated_at ? (
            `${updatedAtDate.getFullYear()}-${updatedAtDate.getMonth() + 1}-${updatedAtDate.getDate()}`
          ) : (
            <Chip size='small' label='New' />
          )
          return (
            <Card
              variant='outlined'
              key={auth?.id}
              className='service-block service-block__no-hover service-block__no-description flex-spaceBetween-center  w-100'
            >
              <Box className='flex-start-center gap-3'>
                <IconWrapper iconUrl={selectedService?.iconurl} size='40px' />
                <Box className='column'>
                  <Typography>{auth?.meta_data?.description}</Typography>
                  <Box className='flex-start-center gap-2'>
                    <Typography variant='fontOne'>{orgUsers?.[auth.user_id]?.name}</Typography>
                    <Typography variant='smallgrey'>{updatedAt}</Typography>
                  </Box>
                </Box>
              </Box>
              {auth.isExpired ? (
                <Box className='flex-center-center gap-1'>
                  <Chip color='error' variant='light' label='Expired' />
                  <Button
                    onClick={() => {
                      openPopup(auth, true)
                    }}
                  >
                    Update
                  </Button>
                </Box>
              ) : null}
            </Card>
          )
        })}
      {showLess && authData?.length > 4 ? (
        <Box className='flex-end-center w-100'>
          <Link component='button' onClick={() => setShowLess(false)}>
            See more
          </Link>
        </Box>
      ) : null}
    </Box>
  ) : (
    <Card
      variant='outlined'
      sx={{
        ...(selectedService?.brandcolor && isColorVisible(selectedService?.brandcolor)
          ? { borderTop: `2px solid ${selectedService?.brandcolor}` }
          : {}),
        maxHeight: '100px !important',
        height: '100% !important'
      }}
      className='service-block pos-rel  flex-spaceBetween-center  w-100'
    >
      <Box className='flex-spaceBetween-center w-100'>
        <Typography className='service-description '>{selectedService?.description}</Typography>
        <Box>
          <Button
            variant='outlined'
            onClick={() => openPopup({ service_id: selectedService?.rowid }, false)}
            startIcon={<IconWrapper iconUrl={selectedService?.iconurl} size={24} />}
          >
            Connect {selectedService?.name}
          </Button>
        </Box>
      </Box>
    </Card>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(AuthenticationsforSpecificplug), [ParamsEnums.orgId, ParamsEnums.projectId, ParamsEnums.scriptId])
)
