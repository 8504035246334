import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getDataFromRedisDb, getScopesOfParticularAuth } from '../../api/index'
import { ALERTTYPES } from '../../enums'
import { getOnePluginData, useActionsOrTriggerForAuth, useAuth } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { sendAlert } from '../../utils/alertUtility.ts'
import { AuthenticateAuth2Plugin } from './authenticateAuth2Plugin.tsx'
import { AuthenticatePlugin } from './authenticatePlugin.tsx'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getAllQueryParams = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const params = {}

  // Iterate over query parameters using forEach
  queryParams.forEach((value, key) => {
    params[key] = value
  })

  return params
}
/*
 * Middleware component for basic and auth2 authentication page.
 * It will check if the user is on DH or Embed mode and redirect to the respective page.
 */
export function AuthPage() {
  const { serviceId, authId } = useParams()
  const queryParams = getAllQueryParams()
  const location = useLocation()
  const [status, setStatus] = useState(!queryParams['_key'])
  const backButton = location?.state?.backButton
  const authData = useAuth(serviceId || '', authId || '', status, Boolean(queryParams?.isUserOnDH)).data
  const plugData = getOnePluginData(serviceId || '', status, Boolean(queryParams?.isUserOnDH))?.data
  const actionList = useActionsOrTriggerForAuth(serviceId || '', 'actionversion', status ?? '')?.data

  const [scopesToUpdateToken, setScopesToUpdateToken] = useState()
  const scopesSeperator = authData?.scopeseperatedby === 'space' ? ' ' : ','
  const [scopesLoaded, setScopesLoaded] = useState(false)

  useEffect(() => {
    if (authData && actionList) {
      getScopesOfThisToken()
    }
  }, [authData, actionList])

  useEffect(() => {
    if (queryParams) {
      if (queryParams['_key']) {
        setCookiesFromredis()
      } else {
        setStatus(true)
      }
    }
  }, [queryParams])
  const setCookiesFromredis = async () => {
    const getDataFromRedis = await getDataFromRedisDb(queryParams['_key'])
    if (getDataFromRedis) {
      sessionStorage.setItem('ssoEmbedToken', getDataFromRedis?.token)
    }
    setStatus(true)
  }
  const getScopesOfThisToken = async () => {
    const dataArray = []
    const actionIds = queryParams?.actionid?.split(',')
    const authDataScopes = authData && JSON.parse(authData?.queryparams)

    if (actionIds && actionList) {
      actionIds.forEach((id) => {
        const action = actionList[id]
        if (action && action.scopes) {
          dataArray.push(...action.scopes?.split(scopesSeperator))
        }
      })
    }

    if (queryParams?.authidtoupdatetoken) {
      try {
        const updateAuthTokens = await getScopesOfParticularAuth(queryParams?.authidtoupdatetoken)
        if (updateAuthTokens && updateAuthTokens.scope) {
          dataArray.push(...updateAuthTokens.scope)
        }
      } catch (error) {
        sendAlert(error, ALERTTYPES.FRONTEND, 'getScopesOfParticularAuth api alert')
      }
    }

    if (authDataScopes && authDataScopes.scope) {
      dataArray.push(...authDataScopes.scope?.split(scopesSeperator))
    }

    const uniqueScopes = Array.from(new Set(dataArray))
    const scopes = uniqueScopes.join(scopesSeperator)

    setScopesToUpdateToken(scopes)
    setScopesLoaded(true)
  }

  return (
    <div className='flex-col-center-center'>
      {authData?.type &&
        scopesLoaded &&
        (authData?.type === 'Basic' ? (
          <AuthenticatePlugin authInfo={authData} plugData={plugData} backButton={backButton} />
        ) : (
          <AuthenticateAuth2Plugin // This will called for Auth1 and Auth2 type.
            authInfo={authData}
            plugData={plugData}
            scopes={scopesToUpdateToken}
            backButton={backButton}
            actionId={location?.state?.actionId}
          />
        ))}
    </div>
  )
}
