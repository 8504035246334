import React from 'react'
import DescriptionIcon from '@mui/icons-material/Description'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, Box } from '@mui/material'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { ParamsEnums, Tabnames } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { closeStepSlider, setIsScriptDrawerOpen } from '../../store/appInfo/appInfoSlice.ts'
import { setDryRunResponseForScript } from '../../store/scripts/scriptsSlice'
import config from '../../config'

function GlobalData(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { allProjects } = useCustomSelector((state) => ({
    allProjects: state?.projects?.projects[props?.orgId]
  }))

  const navigateToFlowPage = async (id, projectId, serviceId) => {
    dispatch(closeStepSlider())
    dispatch(setDryRunResponseForScript(''))
    const urlToNavigate = `${config.projectsBaseUrl}/${props?.orgId}/${projectId}/service/${serviceId}${config.workflowBaseUrl}/${id}/${Tabnames.DRAFT}`
    navigate(urlToNavigate)
    dispatch(setIsScriptDrawerOpen(true))
    props?.setDrawerOpen(false)
  }

  const sortedGlobalData = props?.globalData?.data?.sort((a, b) => {
    const titleA = allProjects?.active?.[a.project_id]?.title || allProjects?.deleted?.[a.project_id]?.title
    const titleB = allProjects?.active?.[b.project_id]?.title || allProjects?.deleted?.[b.project_id]?.title
    return titleA?.localeCompare(titleB)
  })

  return (
    <Box className='h-90vh pb-120 '>
      {sortedGlobalData.length !== 0 ? (
        sortedGlobalData.map((singleElement, index) => (
          <Box
            key={singleElement.id}
            className={`flex-col px-2 mb-3 flex-start-center ${index === sortedGlobalData.length - 1 ? 'pb-120' : ''}`}
          >
            <Typography className='w-100 global-search-h text-overflow-eclipse'>
              {allProjects?.active?.[singleElement.project_id]?.title || allProjects?.deleted?.[singleElement.project_id]?.title}
            </Typography>
            <List className='flex-col px-1 w-100' darkbg>
              {singleElement?.script_id.length > 0 &&
                [...singleElement.script_id]
                  .sort((a, b) => (a.title || '').localeCompare(b.title || ''))
                  .map((singleScript) => (
                    <ListItem disablePadding key={singleScript.id} className='flex-start-center w-100 search-result'>
                      <ListItemButton
                        className='w-100'
                        onClick={() => navigateToFlowPage(singleScript.id, singleElement.project_id, singleScript?.serviceId)}
                      >
                        <ListItemIcon>
                          <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary={singleScript.title || singleScript.id} />
                      </ListItemButton>
                    </ListItem>
                  ))}
            </List>
          </Box>
        ))
      ) : (
        <Box className='h-100 flex-col-center-center'>
          <Typography className='demo-search-text' disabled>
            Oops! It seems there is no flow or project with that name yet.
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(GlobalData), [ParamsEnums.projectId, ParamsEnums.orgId]))
