import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { useDispatch } from 'react-redux'
import { Box, TextField, Typography } from '@mui/material'
import axios from 'axios'
import DialogContent from '@mui/material/DialogContent'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import { openModalRedux } from '../../../store/appInfo/appInfoSlice.ts'
import { addModalData } from '../../../store/modal/modalSlice'
import selectActiveUser from '../../../store/user/userSelector'

export default function PluginRequestForm() {
  const [next, setNext] = useState(false)
  const { modalType, userData } = useCustomSelector((state) => ({
    modalType: state.appInfo?.modalType,
    userData: selectActiveUser(state)
  }))
  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(addModalData({ plugData: { pluginName: '', pluginIconUrl: '' }, modalType: 'requestPlug' }))
    dispatch(openModalRedux({ modalType: '' }))
  }
  useEffect(() => {
    setNext(false)
  }, [modalType])

  const handleSubmit = (e) => {
    e.preventDefault() // Prevent default form submission behavior
    const serviceName = document.getElementById('serviceName').value
    const usecaseValue = document.getElementById('usecase').value
    setNext(true)
    const url = 'https://flow.sokt.io/func/scriPIvL7pBP'
    const content = {
      plugName: serviceName,
      useCase: usecaseValue,
      environment: process.env.REACT_APP_API_ENVIRONMENT,
      userId: userData?.userId,
      userEmail: userData?.email
    }

    axios.post(url, content)
  }

  return (
    <Dialog
      open={modalType === 'requestPlug'}
      PaperProps={{
        style: {
          width: '600px'
        }
      }}
      onClose={handleClose}
    >
      <DialogContent>
        {!next && (
          <Box component='form' className='column gap-4' onSubmit={handleSubmit}>
            <Box className='column gap-3'>
              <Typography>Service Name :</Typography>
              <TextField required id='serviceName' placeholder='Enter Service Name' />
            </Box>

            <Box className='column gap-3'>
              <Typography>
                Please provide details about your use case in the text field below. This helps us prioritize your request and better
                understand your use case.
              </Typography>
              <TextField required multiline minRows={6} fullWidth id='usecase' name='usecase' placeholder='Describe your use case' />
            </Box>

            <Button style={{ marginLeft: 'auto', marginTop: 'auto' }} variant='contained' type='submit'>
              Next
            </Button>
          </Box>
        )}
        {next && (
          <Box className='column gap-2'>
            <Typography fontWeight='600'>Thank you for submitting your request!</Typography>
            <div>
              <Typography>Can you help us in building, reviewing, and testing? It will take approximately 40 minutes.</Typography>
              <Typography>In return, we will make your subscription free for an additional 3 months.</Typography>
            </div>
            <div className='flex-start-center gap-2'>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  setNext(false)
                  handleClose()
                  window.open('https://calendly.com/rpaliwal71/15-mins', '_blank')
                }}
              >
                Yes, I will help
              </Button>
              <Button
                variant='outlined'
                onClick={() => {
                  handleClose()
                }}
              >
                No, I do not want this perk
              </Button>
            </div>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
