import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'

import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  DialogTitle,
  IconButton,
  DialogContent,
  Button,
  DialogContentText
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SecurityIcon from '@mui/icons-material/Security'
import CodeIcon from '@mui/icons-material/Code'
import { useLocation, useNavigate, Link as RouterLink, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined'
import PersonIcon from '@mui/icons-material/Person'
import ConstructionIcon from '@mui/icons-material/Construction'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import SettingsIcon from '@mui/icons-material/Settings'
import ProjectList from '../../components/projectdashboard/projectList/projectList.tsx'
import CustomModal from '../../components/customModal/customModal'
import { fetchProjects } from '../../store/projects/projectsThunk'
import { errorToast } from '../../components/customToast'
import config from '../../config'
import './projectPage.scss'
import { updateAppInfoState, closeModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import getAllUsers from '../../store/orgUsers/orgUsersThunk'
// import SearchBar from '../../components/projectdashboard/searchBar'
import { ApiTypes, ModalTypeEnums, ParamsEnums } from '../../enums'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { listOfUsers } from '../../store/orgUsers/orgUsersSelector'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import SelectedWorkspace from '../../components/workspaceComponent/selectedWorkspace.tsx'
import AuthorizeOrgModal from '../../components/oauthAuthorization/OauthAuthorizationModal'
import { switchOrgId } from '../../utils/utilities'
import NotificationSlider from '../../components/Notifications/NotificationSlider.tsx'
import PluginRequestForm from '../../components/plugin/pluginComponents/pluginRequestForm.tsx'
import CreateNewPopover from '../../components/projectdashboard/createNewPopover.tsx'
import VideoPlayer from '../../components/videoPlayer/videoPlayer.tsx'
// eslint-disable-next-line

function ProjectPageV2(props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const projectSliderRef = useRef('')
  const orgId = props?.orgId
  const projectId = props?.projectId

  const { orgs, modalType, mode, appInfo, usersList, allProjects, currentOrgId } = useCustomSelector((state: $ReduxCoreType) => ({
    orgs: state?.orgs?.orgs,
    isModalOpen: state.appInfo.isModalOpen,
    modalType: state.appInfo.modalType,
    mode: state.appInfo.mode,
    appInfo: state.appInfo,
    usersList: listOfUsers(state),
    allProjects: state?.projects?.projects[orgId],
    currentOrgId: state.appInfo?.currentOrgId
  }))
  const activeProjects = allProjects?.['active'] || {}
  const deletedProjects = allProjects?.['deleted'] || {}

  const getUsersList = async () => {
    dispatch(getAllUsers({ orgId }))
  }
  const handleClose = () => {
    // setOpen(false)
    // setAnchorEl(null)
    dispatch(closeModalRedux({}))
  }
  const checkProjectPageRoute = () => {
    const res = activeProjects[projectId] || deletedProjects[projectId]
    if (res === null && projectId) {
      navigate(`${config.projectsBaseUrl}`)
    }
  }

  useEffect(() => {
    checkProjectPageRoute()
  }, [projectId])

  useEffect(() => {
    const handleProjects = async () => {
      if (orgId?.length) {
        if (orgId !== currentOrgId) {
          try {
            await switchOrgId(orgId, orgId)
          } catch (error) {
            errorToast("You don't have access to this organization")
            navigate(`${config.orgBaseUrl}`)
          }
        }
        dispatch(updateAppInfoState({ currentOrgId: orgId }))
        if (mode !== ApiTypes.EMBED) dispatch(fetchProjects({ orgId, type: ApiTypes.FLOW }))
        getUsersList()
      }
    }
    handleProjects()
  }, [orgId])

  const [isExpanded, setisExpanded] = useState(false)

  const handleMembers = () => {
    navigate(`${config.orgBaseUrl}/${orgId}/invite`)
  }

  const handleMetrics = () => {
    navigate(`${config.orgBaseUrl}/${orgId}/metrics`)
  }

  const handleSettings = () => {
    navigate(`${config.orgBaseUrl}/${orgId}/setting`)
  }

  return (
    <Box className={`project-page-cont flex overflow-scroll-y p-2 border-none `}>
      <Box ref={projectSliderRef} className='project-page flex-col w-100 h-100 p-0 gap-0'>
        {/* OrgComponent */}
        <Box className='flex-col'>
          <Box className='w-100'>
            <SelectedWorkspace />
          </Box>

          {/* Seacrch everywhere scetion */}
        </Box>
        <CreateNewPopover defaultProjectId={`proj${orgId}`} />

        <List className='mb-3'>
          <ListItem
            disablePadding
            onClick={handleMetrics}
            className={`${location.pathname.includes('metrics') ? 'selected-list-item' : ''}`}
          >
            <ListItemButton>
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText primary='Metrics' />
              <ChevronRightIcon className='transform-8px' />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            onClick={() => navigate(`${config.authBaseUrl}/${orgId}`)}
            className={`${location.pathname.startsWith(config.authBaseUrl) ? 'selected-list-item' : ''} `}
          >
            <ListItemButton>
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary='Authentications' />
              <ChevronRightIcon className='transform-8px' />
            </ListItemButton>
          </ListItem>

          {/* list of all projects */}
          {/* <Box  className={location.pathname.includes('metrics') ? 'selected-list-item p-2' : 'list-item p-2 '}>
              <Typography selected={location.pathname === `${config.orgBaseUrl}/${orgId}/metrics`} darkbg='true' variant='base'>
                Metrics
              </Typography>
            </Box> */}

          <Box>
            <ProjectList />
          </Box>
        </List>
        {/* list of all other menus */}
        <Box>
          <List className='flex-col'>
            <Box>
              <ListItem disablePadding onClick={() => navigate(`${config.orgBaseUrl}/${orgId}/chatbot`)}>
                <ListItemButton>
                  <ListItemIcon>
                    <AutoFixHighIcon />
                  </ListItemIcon>
                  <ListItemText primary='Ask AI' />
                  <ChevronRightIcon className='transform-8px' />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                onClick={() => {
                  dispatch(updateAppInfoState({ modalType: `modelForOpenTalktosupport` }))
                }}
              >
                <ListItemButton target='_blank'>
                  <ListItemIcon>
                    <CircleIcon color='success' />
                  </ListItemIcon>
                  <ListItemText primary='Talk to Support' />
                  <ChevronRightIcon className='transform-8px' />
                </ListItemButton>
              </ListItem>
            </Box>
            <ListItem disablePadding onClick={handleSettings}>
              <ListItemButton>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary='Settings' />
                <ChevronRightIcon className='transform-8px' />
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              onClick={handleMembers}
              className={`${location.pathname.includes('invite') ? 'selected-list-item' : ''} `}
            >
              <ListItemButton>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={`Members (${usersList ? usersList?.length : 0})`} />
                <ChevronRightIcon className='transform-8px' />
              </ListItemButton>
            </ListItem>
            <NotificationSlider />
            {modalType && (
              <CustomModal openModal={modalType === `modelForOpenTalktosupport`} onClose={() => dispatch(closeModalRedux({}))}>
                <DialogTitle id='alert-dialog-title' className='pt-4'>
                  Continue to support portal
                </DialogTitle>
                <IconButton variant='iconbuttonModal' aria-label='close' className='w-fitcontent' onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>
                    <Typography variant='subtitle1'>
                      To chat with the support team, click Visit Support Portal and provide details about your issue. This helps us gather
                      information for your chat and offers alternative resources and options for your request.
                    </Typography>
                  </DialogContentText>

                  <Box className='  mt-50px flex-end-top gap-2'>
                    <Button variant='outlined' size='medium' onClick={() => handleClose()}>
                      Close
                    </Button>
                    <Link to='https://viasocket.com/faq' target='_blank' onClick={() => handleClose()}>
                      <Button variant='contained' size='medium'>
                        Visit support portal
                      </Button>
                    </Link>
                  </Box>
                </DialogContent>
              </CustomModal>
            )}
            <Accordion onClick={() => setisExpanded(!isExpanded)} sx={{ boxShadow: 'none' }}>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon className='items-center' />}
                sx={{
                  border: 'none',
                  background: 'transparent',
                  padding: '0',
                  margin: '0',
                  '&:hover': {
                    background: 'transparent'
                  }
                }}
                aria-controls='panel1-content'
                id='panel1-header'
                className='p-0 my-1 mx-0  dh-accordian-sidebar w-100'
              >
                <List>
                  <ListItem disablePadding>
                    <div className='flex-start-center ml-2'>
                      <ListItemIcon>
                        <CodeIcon sx={{ width: '20px', height: '20px' }} />
                      </ListItemIcon>
                      {/* <ListItemText primary='Developer Hub' /> */}
                      <Typography variant={isExpanded ? 'smallHeadinghere ' : 'base'} className='flex-start-center pl-1  accordion-typo'>
                        Developer Hub
                      </Typography>
                    </div>
                  </ListItem>
                </List>
              </AccordionSummary>
              <AccordionDetails sx={{ border: 'none', padding: '0' }}>
                <List>
                  <ListItem disablePadding>
                    <ListItemButton component={RouterLink} to='https://techdoc.viasocket.com' target='_blank'>
                      <ListItemIcon>
                        <NoteAddOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary='API Doc' />
                    </ListItemButton>
                  </ListItem>
                  {/* oauth start */}
                  <ListItem
                    disablePadding
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`${config.orgBaseUrl}/${orgId}/oauth`)
                    }}
                    className={`${location.pathname.includes('oauth') ? 'selected-list-item' : ''} `}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <LockPersonOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary='OAuth' />
                    </ListItemButton>
                  </ListItem>
                  {/* oauth end */}
                  <ListItem
                    disablePadding
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`${config.developerBaseUrl}/${orgId}`)
                    }}
                    className={`${location.pathname.includes(config.developerBaseUrl) ? 'selected-list-item' : ''} `}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <ConstructionIcon />
                      </ListItemIcon>
                      <ListItemText primary='Plugin Builder' />
                      {/* <ChevronRightIcon className='transform-8px' /> */}
                    </ListItemButton>
                  </ListItem>

                  <ListItem
                    disablePadding
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`/integrations/${orgId}`)
                    }}
                    className={`${location.pathname.startsWith('/integrations') ? 'selected-list-item' : 'accordion-list-item '}`}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <IntegrationInstructionsOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary='Integrations' />
                      {/* <ChevronRightIcon className='transform-8px' /> */}
                    </ListItemButton>
                  </ListItem>
                </List>
              </AccordionDetails>
            </Accordion>
          </List>
        </Box>
      </Box>
      <CustomModal
        className='orgbox__model'
        fullScreen={false}
        openModal={appInfo.isModalOpen && appInfo.modalType === ModalTypeEnums.AUTHORIZE_ORG}
        // openModal={appInfo.isModalOpen }
      >
        <AuthorizeOrgModal selectedOrg={orgs[orgId]} />
      </CustomModal>
      <PluginRequestForm />
      <VideoPlayer
        openModal={['webhook', 'email', 'cron'].includes(modalType)}
        type={modalType}
        onClose={() => dispatch(closeModalRedux())}
      />
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(ProjectPageV2), [ParamsEnums.projectId, ParamsEnums.orgId]))
