import { errorToast } from '../components/customToast'
import { ALERTTYPES, ApiTypes } from '../enums'

import axios from '../interceptor/interceptor'
import { sendAlert } from '../utils/alertUtility.ts'
import { jsonToTypeOrRaw } from '../utils/utilities'

const URL = process.env.REACT_APP_API_BASE_URL
const MSG91_URL = 'https://routes.msg91.com/api/'

const signUpUser = async (data) => {
  try {
    const User = await axios.post(`${URL}/users`, data)
    return User
  } catch (error) {
    throw new Error(error)
  }
}

const getTemplatesTriggerWise = async (eventId, filter = 'serviceId') => {
  try {
    return axios.get(`${URL}/templates/get-all-templates/trigger/${eventId}${filter ? `?filterby=${filter}` : ''}`)
  } catch (error) {
    throw new Error(error)
  }
}
const getAllNotifications = async () => {
  try {
    return axios.get(`${URL}/notification/get-all-notification`)
  } catch (error) {
    throw new Error(error)
  }
}
const setNotification = async (event, data) => {
  try {
    return axios.post(`${URL}/notification/${event}/change-status`, data)
  } catch (error) {
    throw new Error(error)
  }
}
const switchOrg = async (data) => {
  try {
    const org = await axios.post(`${URL}/orgs/switchOrg`, data)
    return org
  } catch (error) {
    throw new Error(error)
  }
}

const logoutUserFromMsg91 = async () => {
  try {
    const User = await axios.delete(`${MSG91_URL}c/logout`)
    return User
  } catch (error) {
    throw new Error(error)
  }
}

const getCurrentUser = async () => {
  try {
    const currentUser = await axios.get(`${URL}/orgs/user-org/details`)
    return currentUser?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const getProjects = async (orgId, type = ApiTypes.FLOW, createDefaultProject = false) => {
  try {
    const getProject = await axios.get(
      `${URL}/orgs/${orgId}/projects?type=${type}${createDefaultProject ? '&createDefaultProject=true' : ''}`
    )
    return getProject
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const getOneProject = async (projectId) => {
  try {
    const oneProject = await axios.get(`${URL}/projects/${projectId}`)
    return oneProject
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const getOneScripts = async (scriptId, projectId, type = ApiTypes.FLOW) => {
  try {
    const getSingleScript = await axios.get(`${URL}/projects/${projectId}/scripts/${scriptId}?type=${type}`)
    return getSingleScript
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const getAllScrpits = async (projectId, type = ApiTypes.FLOW) => {
  try {
    const getAllScript = await axios.get(`${URL}/projects/${projectId}/scripts?type=${type}`)
    return getAllScript
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const getAllTemplateAndScript = async (projectId) => {
  try {
    const getAllTemplateAndScriptData = await axios.get(`${URL}/projects/${projectId}/templates`)
    return getAllTemplateAndScriptData
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const createFlowFromTemplate = async (projectId, templateId, body) => {
  try {
    const data = await axios.post(`${URL}/projects/${projectId}/template/${templateId}`, body)
    return data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const updateScripts = async (id, data) => {
  try {
    const projectId = data?.project_id
    const uptateScript = await axios.put(`${URL}/projects/${projectId}/scripts/${id}`, data)
    return uptateScript
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const updateProject = async (id, data) => {
  try {
    const res = await axios.put(`${URL}/orgs/${data.org_id}/projects/${id}`, data)
    return res
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const updateScriptStatus = async (projectId, scriptId, status) => {
  try {
    return await axios.put(`${URL}/projects/${projectId}/scripts/${scriptId}/status?status=${status}`)
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const updateProjectStatus = async (orgId, projectId, status) => {
  try {
    const updateProjectStatus = await axios.put(`${URL}/orgs/${orgId}/projects/${projectId}/status?status=${status}`)
    return updateProjectStatus
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const dryRunScripts = async (id, data) => {
  try {
    const obj = data?.data?.queryString

    // url with params
    let url = `${process.env.REACT_APP_VM_URL}/func/${id}/test`
    if (obj?.length > 0) {
      url = `${url}?${obj[0].name}=${obj[0].value}`
      for (let i = 1; i < obj.length; i++) {
        url = `${url}&${obj[i].name}=${obj[i].value}`
      }
    }

    let headers = data?.data.headers
    const response = {}
    for (let i = 0; i < headers.length; i++) {
      const tempKey = headers[i].name
      if (tempKey?.length !== 0) {
        response[tempKey] = headers[i].value
      }
    }

    headers = response
    const body = data?.data.postData.text
    const datatosend = {
      code: data.code,
      variables: body,
      mimeType: data?.data.postData.mimeType,
      methodType: data?.data?.method || 'GET',
      type: data.type,
      isPublishedTab: data.isPublishedTab
    }
    let dryRunScript
    switch (data?.data?.method) {
      case 'GET':
        dryRunScript = await axios.get(url, { headers })
        break
      case 'POST':
        dryRunScript = await axios.post(url, datatosend, { headers })
        break
      case 'PUT':
        dryRunScript = await axios.put(url, datatosend, { headers })
        break
      case 'PATCH':
        dryRunScript = await axios.patch(url, datatosend, { headers })
        break
      case 'DELETE':
        dryRunScript = await axios.delete(url, { headers, data: datatosend })
        break
      default:
        throw new Error(`Unsupported method: ${data?.data?.method}`)
    }
    return dryRunScript
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}
const createFlowFromAI = async ({ promptValue, data }) => {
  // deprecated
  try {
    const { orgId, projectId, scriptId } = data
    const getAiResponse = await axios.post(`${URL}/openai/flow-by-ai`, { prompt: promptValue })
    const json = getAiResponse?.data?.data

    const getFlow = await axios.post(`${URL}/testing/org/${orgId}/project/${projectId}/scriptFunction/${scriptId}`, { json })
    return { success: getFlow?.data?.success }
  } catch (error) {
    console.error({ message: error })
    return { message: error }
  }
}
const generateFlowFromAI = async (data) => {
  try {
    const { orgId, projectId, scriptId, json } = data
    const getFlow = await axios.post(`${URL}/testing/org/${orgId}/project/${projectId}/scriptFunction/${scriptId}`, { json })
    return { success: getFlow?.data?.success, flowData: getFlow?.data?.data?.script?.data }
  } catch (error) {
    console.error({ message: error })
    return { message: error }
  }
}
const createScripts = async (data, type = ApiTypes.FLOW) => {
  try {
    data.type = type
    const res = await axios.post(`${URL}/projects/${data?.project_id}/scripts`, data)
    return res
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const createOrg = async (data) => {
  try {
    const result = await axios.post(`${URL}/orgs/createOrg`, data)
    return result
  } catch (error) {
    throw new Error(error)
  }
}

const getOneFunction = async ({ scriptId, functionId }) => {
  try {
    const res = await axios.get(`${URL}/scripts/${scriptId}/functions/${functionId}`)
    return res
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const dryRunFunction = async ({ scriptId, data }) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_VM_URL}/scripts/${scriptId}/functions/test`, data)
    return res
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const createdProject = async (data, type = ApiTypes.FLOW) => {
  try {
    data.type = type
    const res = await axios.post(`${URL}/orgs/${data?.org_id}/projects`, data)
    return res
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

// User-Org Mapping
const getUserOrg = async (filter) => {
  try {
    const res = await axios.get(`${URL}/orgs/getCompanies/all?${filter}`)
    return res?.data?.data?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const getUsersByOrgId = async (pageNo) => {
  try {
    const getUsersByOrgID = await axios.get(`${URL}/orgs/orgusers/all?pageNo=${pageNo}&meta[type]=embed&meta_not=1`)
    return getUsersByOrgID?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const sendInvite = async (data) => {
  try {
    const sendInvite = await axios.post(`${URL}/orgs/addUser`, data)
    return sendInvite?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const deleteUserFromOrganization = async (orgId, userId) => {
  try {
    const deleteUserFromOrg = await axios.delete(`${URL}/userorg/${orgId}/deleteUser/${userId}`)
    return deleteUserFromOrg
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const leaveCompany = async (data) => {
  try {
    const leaveUsers = await axios.post(`${URL}/orgs/leave-org`, data)
    return leaveUsers?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const getFunctionCodeFromAI = async (data) => {
  // pending from dh
  const getFunctionCode = await axios.post(`${URL}/openai/functioncode`, data)
  return getFunctionCode
}

export const getPlugDataV2 = async () => {
  try {
    return (await axios.get(`${URL}/dbdash/getplugin`)).data?.data?.data?.rows || []
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

export const getAllTriggersDataV2 = async () => {
  try {
    return (await axios.get(`${URL}/airtable/plugin`)).data?.data?.records || []
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const createOrUpdateTrigger = async (scriptId, data) => {
  try {
    return await axios.post(`${URL}/scripts/${scriptId}/trigger`, data)
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Invalid Statement')
    throw new Error(error)
  }
}

const getAuth = async ({ orgid, serviceid, versionid, projectid, scriptid }) => {
  try {
    const getAuth = await axios.get(
      `${URL}/authtoken/orgid/${orgid}/projectid/${projectid}/scriptid/${scriptid}/serviceid/${serviceid}/version/${versionid}`
    )
    return getAuth
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const getLogsOfScript = async (projectId, scriptId, page, filter = {}, inProject = false, limit = 40, key = '', advanced = false) => {
  if (filter !== '') {
    filter = JSON.stringify(filter)
  }
  try {
    const response = await axios.get(
      `${URL}/logs/projectid/${projectId}/scriptid/${scriptId}?page=${page}&limit=${limit}&key=${key}&search=scriptId&advanced=${advanced}&filter=${filter}&inProject=${inProject}`
    )
    return response?.data?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}
const getOneLogFromFlowHitId = async (scriptId, flowHitId) => {
  try {
    const response = await axios.get(`${URL}/logs/scriptid/${scriptId}/singlelog/${flowHitId}`)
    return response?.data?.data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
const getLastInvocation = async (id) => {
  try {
    return (await axios.get(`${URL}/logs/scriptid/${id}/recent`))?.data?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}
export const getApiKeyByName = async (authkeyName) => {
  try {
    const response = await axios.get(`${MSG91_URL}c/authkey?name=${authkeyName}`)
    return response?.data?.data?.data
  } catch (e) {
    return e
  }
}
export const createApiKey = async (name) => {
  try {
    const response = await axios.post(`${MSG91_URL}c/authkey`, {
      name: name,
      throttle_limit: '60:10',
      temporary_throttle_limit: '60:05',
      temporary_throttle_time: '30'
    })
    return response?.data?.data
  } catch (e) {
    return e
  }
}
const verifyBasicAuth = async (data) => {
  // already handled
  try {
    const response = await axios.post(`${URL}/plugin/verify/basicauth`, data)
    return response
  } catch (error) {
    console.error(error)
    return error
  }
}

const redirectToServiceAuthPage = async (data) => {
  // already handled
  const basicAuth = await axios.post(`${URL}/plugin/authenticate`, data)
  return basicAuth
}

const getAuthToken = async (data) => {
  // already handled
  try {
    const response = await axios.post(`${URL}/plugin/service/token`, data)
    return response
  } catch (error) {
    console.error(error)
    return error
  }
}

const getRecommendations = async (serviceSlugName) => {
  try {
    const response = await axios.get(`${URL}/dbdash/recommendation/${serviceSlugName}`)
    return response?.data?.data
  } catch (error) {
    return []
  }
}
const getRecommendationsForMultiplePlugins = async (serviceSlugNames) => {
  try {
    const response = await axios.get(`${URL}/dbdash/getRecommendationsForMultiplePlugins?serviceNames=${JSON.stringify(serviceSlugNames)}`)
    return response?.data?.data
  } catch (error) {
    return []
  }
}
const getRecommendationsForNextAction = async (scriptId, actions = []) => {
  try {
    const response = await axios.get(`${URL}/dbdash/getRecommendationsForNextAction/${scriptId}?actions=${JSON.stringify(actions)}`)
    return response?.data?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    return []
  }
}

/**
 * Fetches plugin data based on the provided table name and payload.
 *
 * @param {Object} payload - The parameters to be sent as query parameters in the GET request.
 * @param {string} tableName - The name of the table from which to fetch the plugin data.
 * @returns {Promise<Array>} - A promise that resolves to an array of rows from the plugin data table.
 * @throws {Error} - Throws an error if the API call fails.
 */

const getPluginData = async (payload, tableName) => {
  // to ensured that data values are strictly limited to strings and arrays
  Object.keys(payload).forEach((key) => {
    const value = payload[key]
    if (typeof value !== 'string' && (!Array.isArray(value) || value.length === 0)) {
      payload[key] = ''
    } else if (Array.isArray(value)) payload[key] = Array.from(new Set(value))
  })
  try {
    const response = await axios.get(`${URL}/dbdash/getpluginbyplugid/${tableName}`, { params: payload })
    return response?.data?.data?.data?.rows
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    return []
  }
}

const getAllBasicAuth = async (orgId, serviceId, versionId) => {
  try {
    const allAuths = await axios.get(`${URL}/authtoken/orgId/${orgId}/serviceid/${serviceId}/version/${versionId}`)
    return allAuths
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}
const getAllAuthorizationDataByOrgId = async (orgId) => {
  try {
    const allAuths = await axios.get(`${URL}/authtoken/org/${orgId}/auth`)
    return allAuths
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const getAuthenticatedPlugs = async (orgId) => {
  try {
    const allAuths = await axios.get(`${URL}/authtoken/org/${orgId}/authplugs`)
    return allAuths?.data?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const deleteUnUsedAuthorizationData = async (orgId, id) => {
  try {
    const allAuths = await axios.delete(`${URL}/authtoken/org/${orgId}/auth/${id}`)
    return allAuths
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

// getting Auth Token for org on embed platform
const getEmbedAuthToken = async (orgId) => {
  try {
    const getEmbedToken = await axios.get(`${URL}/orgs/${orgId}/token`)
    return getEmbedToken
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

// AUTHORIZE-EMEBED-TOKEN.
const authorizeEmbedUser = async () => {
  try {
    return (await axios.get(`${URL}/users/register`))?.data?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const getOrCreateFlowFromTemplate = async (projectId, embedFlowId, templateId) => {
  try {
    return (await axios.post(`${URL}/projects/${projectId}/scripts/getorcreateflow?flow_id=${embedFlowId}&template_id=${templateId}`))?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const duplicateFlow = async (projectId, scriptId, title) => {
  try {
    return (await axios.post(`${URL}/projects/${projectId}/scripts/${scriptId}/duplicate`, { projectId, title }))?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

/**
 * Moves a script to a new project.
 *
 * @param {string} newProjectId - The ID of the new project.
 * @param {string} scriptId - The ID of the script to move.
 * @returns {Promise<Object>} - The response data from the move API.
 * @throws {Error} - If there is an error during the move operation.
 */
const moveFlow = async (newProjectId, scriptId) => {
  try {
    // Make a POST request to the move API endpoint
    const response = await axios.post(`${URL}/projects/${newProjectId}/scripts/${scriptId}/move`, { newProjectId })

    // Return the response data
    return response.data
  } catch (error) {
    console.error(error)

    // Show an error toast with the error message
    errorToast(error?.response?.data?.message || 'Something went wrong!')

    // Throw the error to be handled by the caller
    throw new Error(error)
  }
}

/**
 * Move a project from one organization to another.
 *
 * @param {string} projectId - The ID of the project to move.
 * @param {string} oldOrgId - The ID of the old organization.
 * @param {string} newOrgId - The ID of the new organization.
 * @returns {Promise} A promise that resolves with the response data.
 * @throws {Error} If an error occurs during the API call.
 */
const moveProject = async (projectId, oldOrgId, newOrgId) => {
  try {
    const response = await axios.post(`${URL}/orgs/${oldOrgId}/projects/${projectId}/change`, { newOrgId })
    return response.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw error
  }
}

const updateFlow = async ({ projectId, scriptId, state }) => {
  try {
    const data = await axios.put(`${URL}/projects/${projectId}/scripts/${scriptId}/${state}`)
    // successToast(`Flow ${state === 'publish' ? 'published' : 'reverted'} successfully`)
    return data?.data?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const updateStepOrder = async (scriptId, body) => {
  try {
    return (await axios.post(`${URL}/scripts/${scriptId}/reorder`, body))?.data?.script
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const updateTemplateSetting = async (projectId, templateId, body) => {
  try {
    return await axios.put(`${URL}/projects/${projectId}/template/${templateId}`, body)
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Could not update the Setting.')
    throw new Error(error)
  }
}

const getGlobalData = async (text, orgId) => {
  try {
    return await axios.get(`${URL}/orgs/${orgId}/search?key=${text}`)
  } catch (error) {
    console.error(error)
    throw error
  }
}
const validateCredentials = async ({ client_id, redirect_uri, scope, response_type }) => {
  return (await axios.post(`${URL}/oauth/v1/validate`, { client_id, redirect_uri, scope, response_type }))?.data
}

const createCollectionAndTokenForStorage = async (data) => {
  return (await axios.post('https://socket-plug-services-h7duexlbuq-el.a.run.app/api/v1/token/mongodb', data))?.data
}

const renameOrg = async (body, orgId) => {
  try {
    return await axios.put(`${URL}/orgs/${orgId}`, body)
  } catch (error) {
    console.error(error)
    throw error
  }
}

const parseCurlApi = async (body) => {
  try {
    const response = await axios.post(`${URL}/utility/parse-curl`, body)
    return response?.data?.data
  } catch (error) {
    errorToast(error?.response?.data?.message || 'Failed to parse cURL')
    return null
  }
}
const getStepApi = async (orgId, page, time) => {
  const queryParams = { ...time }

  const url = `${URL}/steps_data/${orgId}?startTime=${queryParams.startTime}&endTime=${queryParams.endTime}&type=step&page=${page}&limit=200`

  try {
    return (await axios.get(url))?.data.data || []
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getEmbedFlowsApi = async (orgId, page, time, projectId) => {
  const queryParams = { ...time }

  const url = `${URL}/steps_data/${orgId}/projectId/${projectId}?startTime=${queryParams.startTime}&endTime=${queryParams.endTime}&type=flow&page=${page}&limit=200`

  try {
    return (await axios.get(url))?.data.data || []
  } catch (error) {
    console.error(error)
    throw error
  }
}

const getFlowsApi = async (orgId, page, time) => {
  const queryParams = { ...time }

  const url = `${URL}/steps_data/${orgId}?startTime=${queryParams.startTime}&endTime=${queryParams.endTime}&type=flow&page=${page}&limit=200`

  try {
    return (await axios.get(url))?.data.data || []
  } catch (error) {
    console.error(error)
    throw error
  }
}

const getTransferDataToShow = async (body, scriptId) => {
  try {
    return (await axios.post(`${URL}/scripts/${scriptId}/transfer/getalldata`, body))?.data
  } catch (error) {
    console.error(error)
    errorToast('Something went wrong! Press refresh button.')
    throw error
  }
}

const transferDataTransferOption = async (scriptId, data) => {
  try {
    return (await axios.post(`${URL}/scripts/${scriptId}/transfer`, data))?.data
  } catch (error) {
    console.error(error)
    // errorToast('Failed to transfer data. Please retry.')
    throw error
  }
}

const getNextPageTransferData = async (scriptId, page) => {
  try {
    return (await axios.get(`${URL}/scripts/${scriptId}/getnextrecords?page=${page}`))?.data
  } catch (error) {
    console.error(error)
    errorToast('Failed to find data. Please retry.')
    throw error
  }
}
const getFilteredTransferData = async (scriptId, filterObj) => {
  try {
    return (await axios.post(`${URL}/scripts/${scriptId}/filter`, filterObj))?.data
  } catch (error) {
    console.error(error)
    errorToast('Failed to find data. Please retry.')
    throw error
  }
}

const generateToken = async (orgId, title) => {
  try {
    return (await axios.post(`${URL}/orgs/${orgId}/apiKey`, { keyName: title })).data
  } catch (error) {
    throw new Error(error)
  }
}

const getAllApiTokens = async (orgId) => {
  try {
    return (await axios.get(`${URL}/orgs/${orgId}/apiKey`))?.data
  } catch (error) {
    throw new Error(error)
  }
}

const deleteApiTokens = async (orgId, tokenId) => {
  try {
    return (await axios.delete(`${URL}/orgs/${orgId}/apiKey/${tokenId}`)).data
  } catch (error) {
    throw new Error(error)
  }
}

const renameApiTokens = async (orgId, tokenId, title) => {
  try {
    return (await axios.put(`${URL}/orgs/${orgId}/apiKey/${tokenId}`, { keyName: title })).data
  } catch (error) {
    throw new Error(error)
  }
}

const storeAuthDetailsToRedis = async (config, key) => {
  try {
    // eslint-disable-next-line
    return (await axios.post(`${URL}/utility/store-authInfo?${key ? 'key=' + key : ''}`, config))?.data?.data
  } catch (error) {
    await sendAlert({ message: error.message }, 'Error in storeAuthDetailsToRedis', 'storeAuthDetailsToRedis')
    throw new Error(error)
  }
}

const getDataFromRedisDb = async (key) => {
  try {
    return (await axios.get(`${URL}/utility/get-authInfo?key=${key}`))?.data?.data
  } catch (error) {
    throw new Error(error)
  }
}

const getScopesOfParticularAuth = async (authId) => {
  try {
    return (await axios.get(`${URL}/authtoken/authInfo/${authId}`)).data.data
  } catch (error) {
    throw new Error(error)
  }
}
const isAuthValid = async (authIdentifier) => {
  try {
    return (await axios.get(`${URL}/authtoken/authvalid/${authIdentifier}`)).data.data
  } catch (error) {
    throw new Error(error)
  }
}

const optimizeCodeByAI = async (apiUrl, payload) => {
  try {
    if (payload.context) payload.context = jsonToTypeOrRaw(payload.context)
    const result = await axios.post(apiUrl, payload)
    return result?.data?.response?.choices?.[0]?.message?.content
  } catch (error) {
    errorToast('Failed to optimize code. Please retry.')
    throw error
  }
}

/* Steps Routes- create update & delete -- start */

const createStep = async (scriptId, body) => {
  try {
    return (await axios.post(`${URL}/scripts/${scriptId}/stepv2`, body))?.data?.script
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

const updateStep = async (scriptId, slugName, body) => {
  try {
    return (await axios.put(`${URL}/scripts/${scriptId}/stepv2/${slugName}`, body))?.data
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}
const deleteStep = async (scriptId, slugName, body) => {
  try {
    return (await axios.delete(`${URL}/scripts/${scriptId}/stepv2/${slugName}`, { data: body }))?.data?.script
  } catch (error) {
    console.error(error)
    errorToast(error?.response?.data?.message || 'Something went wrong!')
    throw new Error(error)
  }
}

/* Steps Routes- create update & delete -- end */

// oauth API
const createOauthClient = async (orgId, title, redirect_uri) => {
  try {
    return (await axios.post(`${URL}/oauth/v1/${orgId}/client`, { title, redirect_uri }))?.data?.data
  } catch (error) {
    console.error(error)
    errorToast('Failed to create client')
    throw error
  }
}
const getAllOauthClient = async (orgId) => {
  try {
    return (await axios.get(`${URL}/oauth/v1/${orgId}/client`))?.data?.data
  } catch (error) {
    console.error(error)
    errorToast('Failed to get clients')
    throw error
  }
}
const getOauthClient = async (orgId, client_id) => {
  try {
    return (await axios.get(`${URL}/oauth/v1/${orgId}/client/?client_id=${client_id}`))?.data?.data
  } catch (error) {
    console.error(error)
    errorToast('Failed to get client')
    throw error
  }
}
const deleteOauthClient = async (orgId, client_id) => {
  try {
    await axios.delete(`${URL}/oauth/v1/${orgId}/client`, {
      data: {
        client_id
      }
    })
  } catch (error) {
    console.error(error)
    errorToast('Failed to delete client')
    throw error
  }
}
const updateOauthClient = async (orgId, payload) => {
  try {
    return await axios.put(`${URL}/oauth/v1/${orgId}/client`, {
      ...payload
    })
  } catch (error) {
    console.error(error)
    errorToast('Failed to delete client')
    throw error
  }
}
export const getNotificationEmbedToken = async (authkey) => {
  try {
    const response = await axios.post(`${URL}/utility/get-notification-embed-token`, { authkey: authkey })
    return response?.data?.data
  } catch (e) {
    return e
  }
}
const removeUserfromOrg = async (orgId, userId) => {
  try {
    const response = await axios.post(`${URL}/orgs/d/${userId}/${orgId}/removeUser`)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}
const getProjectAndScriptsWhereAuthUsed = async (authId) => {
  try {
    const response = await axios.get(`${URL}/authtoken/usedin/${authId}`)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}
// to validate arr before passing to the api
const validateArray = (arr) => {
  return Array.isArray(arr) && arr.every((item) => typeof item === 'string')
}

const getPluginByPluginName = async (plugName, orgId, categories = [], filteredPlugins = [], mode = 'flow') => {
  const validCategories = validateArray(categories) ? categories : []
  const validFilteredPlugins = validateArray(filteredPlugins) ? filteredPlugins : []

  try {
    const response = await axios.get(
      `${URL}/dbdash/getPluginByQuery?query=${plugName}&orgId=${orgId}&mode=${mode}&categories=${JSON.stringify(
        validCategories
      )}&filteredPlugins=${JSON.stringify(validFilteredPlugins)}`
    )
    return response?.data?.data
  } catch (error) {
    sendAlert(error, ALERTTYPES.FRONTEND, 'getPluginByname api alert')
    throw error
  }
}

const getActionsByActionName = async (plugName, filteredActions = []) => {
  const validfilteredActions = validateArray(filteredActions) ? filteredActions : []

  try {
    const response = await axios.get(
      `${URL}/dbdash/getActionsByQuery?query=${plugName}&filteredActions=${JSON.stringify(validfilteredActions)}`
    )
    return response?.data?.data
  } catch (error) {
    sendAlert(error, ALERTTYPES.FRONTEND, 'getActionsByQuery api alert')
    throw error
  }
}

const createCompanyBilling = async (orgId, body) => {
  const response = await axios.post(`${URL}/orgs/${orgId}/bill`, body)
  return response?.data?.data
}

const getCompanyBilling = async (orgId) => {
  try {
    const response = await axios.get(`${URL}/orgs/${orgId}/bill`)
    return response
  } catch (error) {
    sendAlert(error, ALERTTYPES.FRONTEND, 'getCompanyBilling api alert')
    // errorToast('unable to create company billing')
    throw error
  }
}

const updateCompanyBilling = async (orgId, body) => {
  try {
    const response = await axios.put(`${URL}/orgs/${orgId}/bill`, body)
    return response
  } catch (error) {
    sendAlert(error, ALERTTYPES.FRONTEND, 'updateCompanyBilling api alert')
    throw error
  }
}

const getCountries = async () => {
  try {
    const result = await axios.get('https://countriesnow.space/api/v0.1/countries/states')
    const list = result?.data?.data?.map((obj) => obj.name)
    return list
  } catch (error) {
    sendAlert(error, ALERTTYPES.FRONTEND, 'getCountries api alert')
    return []
  }
}
const getStates = async (country) => {
  try {
    const result = await axios.post('https://countriesnow.space/api/v0.1/countries/states', { country })
    const list = result?.data?.data?.states.map((obj) => obj.name)
    return list
  } catch (error) {
    sendAlert(error, ALERTTYPES.FRONTEND, 'getStates api alert')
    errorToast('unable to get countries list')
    return []
  }
}
const getAfterSignupData = async (tableId) => {
  try {
    const result = await axios.get(`${URL}/dbdash/getAfterSignupData/${tableId}`)
    return result?.data?.data
  } catch (error) {
    sendAlert(error, ALERTTYPES.FRONTEND, 'getAfterSignupData api alert')
    errorToast('unable to get data from db')
    return []
  }
}

const getAfterSignupFields = async () => {
  try {
    const result = await axios.get('https://flow.sokt.io/func/scrir7lRVkiF')
    return result?.data
  } catch (err) {
    errorToast('unable to get data from db copy')
    return []
  }
}

// const isAfterSignupCouponCodeValid = async (orgId, couponCode, typeOfCouponCode) => {
//   try {
//     const result = await axios.post(`${URL}/orgs/${orgId}/verifycoupon`, { couponCode, typeOfCouponCode })
//     console.log(result, 'This is api wala data')
//     return result.data.data
//   } catch (error) {
//     sendAlert(error, ALERTTYPES.FRONTEND, 'sendDataToWebhook api alert')
//     return []
//   }
// }

const updateCodeData = async (tableId, rowid) => {
  // this function updates the code data stored in db dash
  try {
    const data = {
      records: [
        {
          where: `rowid='${rowid}'`,
          fields: {
            used: true
          }
        }
      ]
    }
    const result = await axios.patch(`${URL}/dbdash/updatePromoCodeTable/${tableId}`, data)
    return result?.data?.data
  } catch (error) {
    sendAlert(error, ALERTTYPES.FRONTEND, 'updateCodeData api alert')
    errorToast('unable to update promo or expert code in db')
    return []
  }
}

const getSupportedLibraries = async () => {
  try {
    return (await axios.get('https://flow.sokt.io/func/scriZ5apBHzA'))?.data
  } catch (error) {
    return {}
  }
}
const getBrandDetails = async (domain) => {
  try {
    return (await axios.get(`${URL}/utility/brandDetails?domain=${domain}`))?.data
  } catch (error) {
    throw new Error(error?.message)
  }
}
const syncAiJsonScript = async (scriptId) => {
  try {
    return (await axios.post(`${URL}/openai/flow-by-ai-v1/${scriptId}/sync-ai-json-script`))?.data?.data
  } catch (error) {
    throw new Error(error?.message)
  }
}

async function getStepMetricsFailedCount(orgId) {
  try {
    const response = await axios.get(`${URL}/steps_data/failedCount/${orgId}`)
    return response.data?.data?.count
  } catch (err) {
    return null
  }
}

async function getInvocationData(orgId, range) {
  try {
    const response = await axios.get(`${URL}/steps_data/${orgId}/invocation-over-time`, {
      params: {
        range
      }
    })
    return response.data?.data
  } catch (err) {
    return null
  }
}

async function getRtLayerToken() {
  try {
    const response = await axios.get(`${URL}/rtlayer/token`)
    return response.data
  } catch (err) {
    return null
  }
}

export {
  syncAiJsonScript,
  authorizeEmbedUser,
  createCollectionAndTokenForStorage,
  createCompanyBilling,
  createFlowFromAI,
  createFlowFromTemplate,
  // oauth
  createOauthClient,
  createOrUpdateTrigger,
  createOrg,
  createScripts,
  createStep,
  createdProject,
  deleteApiTokens,
  deleteOauthClient,
  deleteStep,
  deleteUnUsedAuthorizationData,
  deleteUserFromOrganization,
  dryRunFunction,
  dryRunScripts,
  duplicateFlow,
  generateFlowFromAI,
  generateToken,
  getAfterSignupData,
  getAfterSignupFields,
  getAllApiTokens,
  getAllAuthorizationDataByOrgId,
  getAllBasicAuth,
  getAllNotifications,
  getAllOauthClient,
  getAllScrpits,
  getAllTemplateAndScript,
  getAuth,
  getBrandDetails,
  getCompanyBilling,
  getCountries,
  getCurrentUser,
  getDataFromRedisDb,
  getEmbedAuthToken,
  getFilteredTransferData,
  getFlowsApi,
  getFunctionCodeFromAI,
  getGlobalData,
  getLastInvocation,
  getLogsOfScript,
  getNextPageTransferData,
  getOauthClient,
  getOneFunction,
  getOneLogFromFlowHitId,
  getOneProject,
  getOneScripts,
  getOrCreateFlowFromTemplate,
  getPluginByPluginName,
  getActionsByActionName,
  getPluginData,
  getProjectAndScriptsWhereAuthUsed,
  getProjects,
  getRecommendations,
  redirectToServiceAuthPage,
  getAuthToken,
  getScopesOfParticularAuth,
  getStates,
  getStepApi,
  getSupportedLibraries,
  getTemplatesTriggerWise,
  getTransferDataToShow,
  getUserOrg,
  getUsersByOrgId,
  // isAfterSignupCouponCodeValid,
  isAuthValid,
  leaveCompany,
  logoutUserFromMsg91,
  moveFlow,
  moveProject,
  optimizeCodeByAI,
  parseCurlApi,
  removeUserfromOrg,
  renameApiTokens,
  renameOrg,
  sendInvite,
  setNotification,
  signUpUser,
  storeAuthDetailsToRedis,
  switchOrg,
  transferDataTransferOption,
  updateCodeData,
  updateCompanyBilling,
  updateFlow,
  updateOauthClient,
  updateProject,
  updateProjectStatus,
  updateScriptStatus,
  updateScripts,
  updateStep,
  updateStepOrder,
  updateTemplateSetting,
  validateCredentials,
  verifyBasicAuth,
  getRecommendationsForNextAction,
  getRecommendationsForMultiplePlugins,
  getStepMetricsFailedCount,
  getAuthenticatedPlugs,
  getInvocationData,
  getRtLayerToken
}
