import React, { useEffect, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import debounce from 'lodash.debounce'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch } from 'react-redux'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import GlobalData from './globalData'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { getGlobalData } from '../../api/index'
import SearchBar from '../projectdashboard/searchBar'
import './search.scss'

function Search({ orgId, projectId, search, setDrawerOpen = () => {} }) {
  const customSugggestSearch = useRef('')
  const searchbarRef = useRef(null)
  const [globalData, setGlobalData] = useState({ data: [], isSearching: false })

  const { isScriptDrawerOpen } = useCustomSelector((state) => {
    return {
      isScriptDrawerOpen: state.appInfo.isScriptDrawerOpen
    }
  })
  const dispatch = useDispatch()

  useEffect(() => {
    if (search) return
    if (customSugggestSearch.current) {
      customSugggestSearch.current.innerHTML = ''
      setGlobalData({ data: [], isSearching: false })
      dispatch(updateAppInfoState({ globalSearchBarValue: '' }))
    }

    if (isScriptDrawerOpen) {
      searchbarRef?.current?.classList?.add('search-bar-container__open')
      searchbarRef?.current?.classList?.remove('search-bar-container__collapsed')
    } else {
      searchbarRef?.current?.classList?.remove('search-bar-container__open')
      searchbarRef?.current?.classList?.add('search-bar-container__collapsed')
    }
  }, [projectId, isScriptDrawerOpen])

  const handleSearchBar = debounce(async (searchBarValue) => {
    dispatch(updateAppInfoState({ globalSearchBarValue: searchBarValue }))
    if (searchBarValue.length === 0 || /^\s*$/.test(searchBarValue)) {
      return setGlobalData((prev) => {
        return { data: prev.data, isSearching: false }
      })
    }

    const getWords = searchBarValue.replace(/\s+/g, ' ')
    try {
      const data = await getGlobalData(getWords, orgId)
      setGlobalData({ data: data.data.data.data, isSearching: true })
    } catch (error) {
      setGlobalData((prev) => {
        return { data: prev.data, isSearching: false }
      })
    }
    return null
  }, 300)

  return (
    <>
      {/* search box */}
      <Box ref={searchbarRef} className='flex-center-center p-3 w-100'>
        <SearchBar placeholder='Search everywhere' handleSearchBar={(text) => handleSearchBar(text)} />
      </Box>

      {globalData.isSearching ? (
        <Box>
          <GlobalData globalData={globalData} setDrawerOpen={setDrawerOpen} />
        </Box>
      ) : (
        <Box className='h-100 flex-col-center-center'>
          <SearchIcon className='search-icon-design' />
          <Typography className='demo-search-text'>Search flow or project in entire workspace.</Typography>
        </Box>
      )}
    </>
  )
}
export default React.memo(addUrlDataHoc(React.memo(Search), [ParamsEnums.projectId, ParamsEnums.orgId, ParamsEnums.search]))
