import React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp'
import { Box } from '@mui/material'

// import { Box } from '@mui/system'

// const ITEM_HEIGHT = 48

export default function FunctionsActionsButton(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    document.getElementById(props?.parentId)?.classList?.remove('opacity-0')
    document.getElementById(`${props?.parentId}_alternate`)?.classList?.add('d-none')
    setAnchorEl(event?.currentTarget)
  }

  const handleClose = (e) => {
    e.stopPropagation()
    document.getElementById(props?.parentId)?.classList?.add('opacity-0')
    document.getElementById(`${props?.parentId}_alternate`)?.classList?.remove('d-none')

    setAnchorEl(null)
  }
  const removeDragIconFromSteps = () => {
    const { parentId } = props
    const id = parentId?.replace('actionbutton', '')
    const parentElement = document.getElementById(`${id}_component`)
    if (parentElement) {
      const countElement = parentElement.querySelector('.count')
      const dragiconElement = parentElement.querySelector('.dragicon')
      const countBoxElement = parentElement.querySelector('.countBox')
      if (countBoxElement) {
        countBoxElement.classList.remove('flex-center-center')
        countBoxElement.classList.add('flex-end-center')
      }

      if (countElement) {
        countElement.classList.remove('d-none')
        countElement.classList.add('flex-end-center')
      }

      if (dragiconElement) {
        dragiconElement.classList.remove('flex-end-center')
        dragiconElement.classList.add('d-none')
      }
      // Add more logic if needed for other elements inside the parent
    }
  }
  const optionsAccordingToBackend = ['Move To Trash', 'Active', 'Pause', 'Active']
  const DynamicClassName = `actionBtnContainer ${props?.className ? props?.className : 'flex-center-center'} ${
    !props?.visible && 'opacity-0'
  }`
  return (
    <Box className='flex-center-center'>
      <Box className={DynamicClassName} id={props?.parentId}>
        {!props?.disable && props?.options?.length > 0 && (
          <MoreHorizSharpIcon
            aria-label='more'
            id='long-button'
            color={props?.darkbg ? 'white' : 'black'}
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup='true'
            onClick={(e) => {
              handleClick(e)
            }}
            className='hiddenMenuButton'
            fontSize='small'
          />
        )}
        <Menu id='long-menu' MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}>
          {props?.options?.map((option) => {
            if (optionsAccordingToBackend[props?.componentDetails?.script?.status || props?.componentDetails?.project?.status] !== option)
              return (
                <MenuItem
                  key={option}
                  className={option === 'Move To Trash' || option === 'Delete' || option === 'Leave' ? 'errorcolor' : ''}
                  onClick={(e) => {
                    // e.preventDefault();
                    e.preventDefault()
                    e.stopPropagation()
                    props?.handleOptionsBtn(option, props?.componentDetails)
                    handleClose(e)
                    if (!props?.interface) removeDragIconFromSteps()
                  }}
                >
                  {option}
                </MenuItem>
              )
            return null
          })}
        </Menu>
      </Box>
      <Box id={`${props?.parentId}_alternate`} className='alternate-icon'>
        {props?.alternateIcon}
      </Box>
    </Box>
  )
}
