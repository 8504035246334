import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import Protected from '../protected'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import ProjectPageV2 from '../../pages/projectPage/projectPageV2.tsx'
import './projectSliderLayoutV2.scss'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { startSetAllNotification } from '../../store/notification/notificationSlice'
import ChatbotInitialize from '../chatbotInitialize/ChatbotInitialize.tsx'
import DrawerWithSearch from '../globalData/DrawerWithSearch'

function ProjectSliderComponent(props) {
  const location = useLocation()
  const dispatch = useDispatch()
  const [openHoverSlider, setOpenHoverSlider] = useState(false)
  window.addEventListener('mousemove', function (event) {
    if (event.clientX <= 16) setOpenHoverSlider(true)
    else if (openHoverSlider && event.clientX > 300) setOpenHoverSlider(false)
  })
  const projectId = location.pathname.includes('projects') ? props?.projectId : null

  const { isProjectSliderOpen } = useCustomSelector((state) => ({
    isProjectSliderOpen: state.appInfo.isProjectSliderOpen
  }))

  useEffect(() => {
    dispatch(startSetAllNotification(props?.orgId))
  }, [props?.orgId])
  return (
    <Box className='flex-start-top overflow-scroll-y w-100  h-100vh'>
      <Box
        className={`${
          projectId && projectId !== `proj${props?.orgId}` && !isProjectSliderOpen && (props?.scriptId || props?.interfaceId)
            ? 'd-none'
            : 'flex'
        } ${
          !isProjectSliderOpen && (props?.scriptId || props?.interfaceId)
            ? 'drawer'
            : !isProjectSliderOpen && (projectId || props?.pluginId)
            ? 'slider_collapsed'
            : 'h-100vh pos-rel '
        } ${openHoverSlider || isProjectSliderOpen ? 'drawer-open' : ''} project_slider `}
      >
        <Protected>
          <ProjectPageV2 />
        </Protected>
      </Box>
      {location.pathname.includes('notification') && <Box id='notification-embed-parent' className='w-100 h-100vh' />}
      <Outlet />
      <Box id='interface-chatbot' className={`${location.pathname.endsWith('chatbot') ? 'pos-rel h-100 w-100' : ''}`}>
        <ChatbotInitialize />
      </Box>
      {!props?.scriptId && location.pathname.startsWith('/projects') && !props?.interfaceId && (
        <Box className='pos-fix right-0 top-0 w-fitcontent zindex-20 p-2'>
          {' '}
          <DrawerWithSearch />
        </Box>
      )}
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(ProjectSliderComponent), [
    ParamsEnums.pluginId,
    ParamsEnums.projectId,
    ParamsEnums.embedding,
    ParamsEnums.scriptId,
    ParamsEnums.orgId,
    ParamsEnums.interfaceId,
    ParamsEnums.pluginId
  ])
)
