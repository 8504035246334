import React, { useEffect, useRef, useState } from 'react'
import debounce from 'lodash.debounce'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import SearchBar from './searchBar'
import { getPluginByPluginName } from '../../api'
import { useFetchPlugins } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { MiscTypes, ParamsEnums } from '../../enums'
import { useCustomSelector } from '../../utils/deepCheckSelector'

/**
 *this is a common search bar for all the plugins gets and gives plugins by api
 it returns servicesarray, isloading boolean
 */
function PluginSearchBar({
  placeholder = 'Search services by name, domain, description...',
  triggers = false,
  orgId,
  getServices = () => {},
  onFocus = () => {},
  onKeyDown = () => {},
  autoFocus = true,
  projectId,
  categorySearch = false,
  mode = 'flow',
  darkbg = false
}) {
  const { categories, filteredPlugins, allPlugins, pluginLoading } = useCustomSelector((state) => {
    const filteredServices =
      (state.appInfo.mode === MiscTypes.EMBED_MODE
        ? state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings?.config?.filteredServices
        : state.appInfo.localConfiguration?.filteredServices) || {}
    const categories = state.appInfo.localConfiguration?.categories || []
    return {
      categories: categorySearch ? categories?.map((category) => category.replaceAll('&', '%26')) || [] : [],
      filteredPlugins: !categorySearch ? Object.keys(filteredServices) || [] : [],
      allPlugins: state?.DHPlugin?.[orgId] || {},
      pluginLoading: state?.DHPlugin?.isLoading || false
    }
  })

  const allServicesObj =
    (mode === 'dh'
      ? { data: allPlugins, isLoading: pluginLoading }
      : !triggers
      ? useFetchPlugins(orgId, categories, filteredPlugins)
      : useFetchPlugins(orgId, categories, filteredPlugins, true)) || {}

  const servicesWithSearch = Object.values(allServicesObj?.data || {})?.map((plugin) => ({
    ...plugin,
    search: `${plugin.name} ${plugin.domain} plug plugin ${plugin.description} service`
  }))
  const [servicesArray, setServicesArray] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const searchTextRef = useRef('')
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setServicesArray(servicesWithSearch)
  }, [allServicesObj.isLoading])

  useEffect(() => {
    getServices({ servicesArray, isLoading: allServicesObj.isLoading || loading || pluginLoading })
  }, [servicesArray, allServicesObj.isLoading, loading, pluginLoading])

  const fetchAndAddPlugins = async (searchTerm) => {
    if (!searchTerm) {
      setServicesArray(servicesWithSearch)
    } else {
      const filteredServices = servicesWithSearch.filter((service) => service.search.includes(searchTerm))
      setServicesArray(filteredServices)
      const data = (await getPluginByPluginName(searchTerm, orgId, categories, filteredPlugins, mode)) || []
      if (searchTerm !== searchTextRef?.current) return
      if (data?.length) {
        const formattedData = data.map((plugin) => ({
          ...plugin,
          search: `${plugin.name} ${plugin.domain} plug plugin ${plugin.description} service`?.toLowerCase()
        }))
        const existingServicesSet = new Set(filteredServices.map((service) => service.rowid))
        // Filter out duplicates and append to servicesArray
        const uniqueFormattedData = formattedData.filter((plugin) => !existingServicesSet.has(plugin.rowid))
        setServicesArray([...filteredServices, ...uniqueFormattedData])
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    fetchAndAddPlugins(searchQuery)
  }, [searchQuery])

  const handleSearchBar = debounce((text) => {
    setSearchQuery(text?.trim()?.toLowerCase())
  }, 100)

  return (
    <SearchBar
      placeholder={placeholder}
      darkbg={darkbg}
      autoFocus={autoFocus}
      handleSearchBar={(text) => {
        searchTextRef.current = text?.trim()?.toLowerCase()
        handleSearchBar(text)
      }}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
    />
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(PluginSearchBar), [ParamsEnums.scriptId, ParamsEnums.tabName, ParamsEnums.orgId, ParamsEnums.projectId])
)
