import { convertArrayToObjectOrNot, getRawType } from '../../utils/utilities'

export const getPostDataForApiPayload = (state: any, condition: any) => {
  const postData = {
    mimeType: '',
    params: [],
    text: '',
    comment: ''
  }

  if (state?.httpRequestType !== 'get') {
    let data

    switch (state?.requestBodyType) {
      case '0':
        data = convertArrayToObjectOrNot(state?.bodyFormData, condition)
        postData[condition === true ? 'params' : 'text'] = data
        postData.mimeType = 'multipart/form-data'
        break

      case '1':
        data = convertArrayToObjectOrNot(state?.bodyFormUrlencoded, condition)
        postData[condition === true ? 'params' : 'text'] = data
        postData.mimeType = 'application/x-www-form-urlencoded'
        break

      case '2':
        postData.text = state?.bodyJsonScript?.length > 0 ? state?.bodyJsonScript : ''
        postData.mimeType = 'application/json'
        break

      case '3':
        data = getRawType(state?.bodyRawScriptType)
        postData.text = JSON.stringify(state?.bodyRawScript)
        postData.mimeType = data
        break

      default:
        break
    }
  }

  return postData
}

export function setBodyTypeAndValue({ mimeType, params, text }: { mimeType: string; params: any; text: string }) {
  const otherInstanceAppend = {
    bodyRawScript: '',
    bodyJsonScript: '',
    bodyRawScriptType: 'Text (text/plain)',
    requestBodyType: '0',
    bodyFormData: [{ name: '', value: '' }],
    bodyFormUrlencoded: [{ name: '', value: '' }]
  }

  if (mimeType?.length === 0) {
    return otherInstanceAppend
  }

  switch (mimeType) {
    case 'multipart/form-data':
      otherInstanceAppend.bodyFormData = params?.length !== 0 ? params : [{ name: '', value: '' }]
      otherInstanceAppend.requestBodyType = '0'
      break
    case 'application/x-www-form-urlencoded':
      otherInstanceAppend.bodyFormUrlencoded = params?.length !== 0 ? params : [{ name: '', value: '' }]
      otherInstanceAppend.requestBodyType = '1'
      break
    case 'application/json':
      otherInstanceAppend.bodyJsonScript = text
      otherInstanceAppend.requestBodyType = '2'
      break
    case 'application/Javascript':
      otherInstanceAppend.bodyRawScriptType = 'JavaScript (application/Javascript)'
      otherInstanceAppend.bodyRawScript = text
      otherInstanceAppend.requestBodyType = '3'
      break
    case 'text/plain':
      otherInstanceAppend.bodyRawScriptType = 'Text (text/plain)'
      otherInstanceAppend.bodyRawScript = text
      otherInstanceAppend.requestBodyType = '3'
      break
    case 'application/xml':
      otherInstanceAppend.bodyRawScriptType = 'XML (application/xml)'
      otherInstanceAppend.bodyRawScript = JSON.parse(text)
      otherInstanceAppend.requestBodyType = '3'
      break
    case 'text/xml':
      otherInstanceAppend.bodyRawScriptType = 'XML (text/xml)'
      otherInstanceAppend.bodyRawScript = text
      otherInstanceAppend.requestBodyType = '3'
      break
    case 'text/html':
      otherInstanceAppend.bodyRawScriptType = 'HTML (text/html)'
      otherInstanceAppend.bodyRawScript = text
      otherInstanceAppend.requestBodyType = '3'
      break
    default:
      break
  }
  return otherInstanceAppend
}
