import React, { useState } from 'react'
import { Drawer, ClickAwayListener } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import Search from './Search'
import SearchBar from '../projectdashboard/searchBar'
import { useCustomSelector } from '../../utils/deepCheckSelector'

/**
 * DrawerWithSearch component
 *
 * This component renders a search drawer that can be toggled open or closed.
 * It uses Material-UI's Drawer and ClickAwayListener components to handle the drawer's behavior.
 * The drawer contains a Search component and is controlled by a state variable.
 * The component also conditionally renders a SearchBar or SearchIcon based on the isProjectSliderOpen state.
 *
 * @param {Object} props - The component props
 * @param {string} props.scriptId - The script ID used to determine the width of the container
 * @returns {JSX.Element} The rendered component
 */

function DrawerWithSearch({ scriptId }) {
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const { isProjectSliderOpen } = useCustomSelector((state) => ({
    isProjectSliderOpen: state.appInfo.isProjectSliderOpen
  }))
  const handleToggleDrawer = (event) => {
    if (event.type !== 'click') {
      return
    }
    setDrawerOpen(!isDrawerOpen)
  }
  const handleClickAway = () => {
    if (isDrawerOpen) {
      setDrawerOpen(false)
    }
  }

  return (
    <>
      {isProjectSliderOpen ? (
        <div
          className={!scriptId ? 'w-100' : isProjectSliderOpen ? 'w-25' : 'w-30'}
          onClick={handleToggleDrawer}
          onKeyDown={handleToggleDrawer}
          role='button'
          tabIndex={0}
          aria-label='Toggle search drawer'
        >
          <SearchBar disabled />
        </div>
      ) : (
        <SearchIcon onClick={handleToggleDrawer} />
      )}
      <Drawer anchor='right' open={isDrawerOpen} transitionDuration={100}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className='h-90vh w-17 pb-120'>
            <Search setDrawerOpen={setDrawerOpen} />
          </div>
        </ClickAwayListener>
      </Drawer>
    </>
  )
}

export default React.memo(addUrlDataHoc(React.memo(DrawerWithSearch), [ParamsEnums.scriptId]))
