import WebSocketClient from 'rtlayer-client'
import { getRtLayerToken } from '../api'
import { getInfoParamtersFromUrl, getProxyAuthToken } from './utilities'

class RTLayerClientSingleton {
  constructor() {
    const proxy_auth_token = getProxyAuthToken()
    if (RTLayerClientSingleton.instance || !proxy_auth_token) {
      return
    }
    this.client = null
    this.rtLayerTokenPromise = null
    RTLayerClientSingleton.instance = this

    // Call init function from constructor
    this.init()
  }

  async init() {
    try {
      if (this.client) return
      if (!this.rtLayerTokenPromise) {
        this.rtLayerTokenPromise = getRtLayerToken()
      }
      const rtLayerToken = await this.rtLayerTokenPromise
      this.rtLayerTokenPromise = null
      if (this.client) return
      this.client = new WebSocketClient(process.env.REACT_APP_RTLAYER_OID, process.env.REACT_APP_RTLAYER_SID, rtLayerToken.token)
    } catch (error) {
      this.rtLayerTokenPromise = null
      this.client = null
      console.error('Failed to initialize WebSocket client:', error)
    }
  }

  async #ensureInitialized() {
    if (!this.client) {
      await this.init()
    }
  }

  async createRtLayerClient() {
    await this.#ensureInitialized()
  }

  async subscribeRtLayerClient(subscriptionId) {
    await this.#ensureInitialized()
    const { orgId } = getInfoParamtersFromUrl()
    this.client.subscribe(`${orgId}-${subscriptionId}`)
  }

  async unSubscribeRtLayerClient(subscriptionId) {
    await this.#ensureInitialized()
    const { orgId } = getInfoParamtersFromUrl()
    this.client.unsubscribe(`${orgId}-${subscriptionId}`)
  }

  async turnOnRtLayerClient(event, cbFunc) {
    await this.#ensureInitialized()
    this.client.on(event, cbFunc)
  }

  async removeListenerFromRtLayerClient(event, cbFunc) {
    await this.#ensureInitialized()
    this.client.removeListener(event, cbFunc)
  }
}
const rtLayerClient = new RTLayerClientSingleton()
export default rtLayerClient
