import { useNavigate } from 'react-router-dom'
import React, { useEffect, useMemo, useState } from 'react'
import { Box, Card, Skeleton, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { CheckCircle } from '@mui/icons-material'
import ApiIcon from '@mui/icons-material/Api'
import './PreviewSection.scss'
import JavascriptIcon from '@mui/icons-material/Javascript'
import { MiscTypes, ParamsEnums, THUNK_CALL_STATUS, Tabnames } from '../../../enums'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import isEqual, { useCustomSelector } from '../../../utils/deepCheckSelector'
import { sendDataToParentInEmbed } from '../../../utils/utilities'
import { saveScriptTemplate, saveScripts } from '../../../store/scripts/scriptsThunk'
import { useFetchActionsTriggers, useFetchPlugins } from '../../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { errorToast } from '../../customToast'
import Templates from '../../sliderLayout/Templates.tsx'
import RenderCombinations from './RenderCombinations.tsx'
import Breadcrumb from '../../breadcrumb/Breadcrumb.tsx'
import IconWrapper from '../../IconWrapper/IconWrapper.tsx'
import CustomCombinationsComponent from './CustomCombinationsComponent.tsx'
import DefaultCombinations from './DefaultCombinations.tsx'
import PluginSearchBar from '../../projectdashboard/PluginSearchBar.tsx'
import config from '../../../config'
import EnabledFlows from './EnabledFlows.tsx'
import { calculateEnabledServiceObject } from '../../../store/scripts/scriptsSelector'
import DeletedAndPausedFlows from './DeletedAndPausedFlows.tsx'

type PreviewSectionProps = {
  orgId: string
  projectId: string
  serviceId: string
  integrationServiceId?: string
  serviceTypeFromProps?: 'both' | 'trigger' | 'action'
  showServicesBeforeEventSelection?: boolean
  showEnabled?: boolean
  filterTrigger?: boolean
  filterAction?: boolean
  showTemplates?: boolean
  featuredCombinationType?: boolean
  showSubHeading?: boolean
  heading?: string
  authenticationComponent?: () => JSX.Element
  isEmbedUrl?: boolean
}
/**
 * PreviewSection component for displaying integration preview and options.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.orgId - The organization ID
 * @param {string} props.projectId - The project ID
 * @param {string} props.serviceId - The service ID
 * @param {string} [props.integrationServiceId=''] - The integration service ID
 * @param {'both' | 'trigger' | 'action'} [props.serviceTypeFromProps='both'] - The service type
 * @param {boolean} [props.showServicesBeforeEventSelection=false] - Whether to show services before event selection
 * @param {boolean} [props.showEnabled=false] - Whether to show enabled services
 * @param {boolean} [props.filterTrigger=false] - Whether to filter triggers
 * @param {boolean} [props.filterAction=false] - Whether to filter actions
 * @param {boolean} [props.showTemplates=false] - Whether to show templates
 * @param {boolean} [props.featuredCombinationType=false] - Whether to use featured combination type
 * @param {boolean} [props.showSubHeading=true] - Whether to show sub-heading
 * @param {string} [props.heading=''] - The heading text
 * @param {Function} [props.authenticationComponent=() => null] - The authentication component
 * @param {boolean} [props.isEmbedUrl] - Whether the component is embedded in a URL
 * @returns {JSX.Element} The PreviewSection component
 */

function PreviewSection({
  orgId,
  projectId,
  serviceId,
  integrationServiceId = '',
  serviceTypeFromProps = 'both',
  showServicesBeforeEventSelection = false,
  showEnabled = false,
  filterTrigger = false,
  filterAction = false,
  showTemplates = false,
  featuredCombinationType = false,
  showSubHeading = true,
  heading = '',
  authenticationComponent = () => null,
  isEmbedUrl,
  pagesubheading = ''
}: PreviewSectionProps) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { currentIntegrationSettings, enabledServiceObject, pluginData, mode, enabledPluginsJsonCombinations } = useCustomSelector(
    (state) => {
      const currentIntegrationSettings = state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings || {}
      return {
        enabledServiceObject: showEnabled
          ? calculateEnabledServiceObject(state, projectId, integrationServiceId, currentIntegrationSettings?.permitsingleflow)
          : {},
        currentIntegrationSettings,
        pluginData: state.allPlugins.pluginData,
        mode: state.appInfo.mode,
        enabledPluginsJsonCombinations: state.scripts.enabledPluginsObject?.combinations || {}
      }
    }
  )
  const [servicesData, setServicesData] = useState({ pluginsArray: [], pluginsObj: {}, isLoading: false })
  const { pluginsArray, pluginsObj, isLoading } = servicesData
  const [availableServices, setAvailableServices] = useState({ plugin: '', trigger: '', function: '' })

  const [selectedService, setSelectedService] = useState(false)
  const { data: bothServicesJson, isLoading: integrationServiceLoading } = useFetchPlugins(orgId, [], [integrationServiceId, serviceId])

  const integrationService = bothServicesJson?.[integrationServiceId]
  const serviceActions = useFetchActionsTriggers(integrationServiceId)?.data || {}
  const serviceTriggers = useFetchActionsTriggers(integrationServiceId, 'trigger')?.data || {}
  const serviceActionsArray = Object.values(serviceActions)
  const serviceTriggersArray = Object.values(serviceTriggers)

  useEffect(() => {
    if (!(selectedService === serviceId || selectedService?.rowid === serviceId)) {
      let availableObject = { trigger: '', plugin: '', function: '' }
      if (serviceType === 'action')
        availableObject = {
          plugin: integrationServiceId,
          trigger: serviceId
        }
      else {
        availableObject = {
          trigger: integrationServiceId
        }
        if (serviceId === 'function' || serviceId === 'api') availableObject['function'] = serviceId
        else availableObject['plugin'] = serviceId
      }
      setAvailableServices(availableObject)
      if (!serviceId) setSelectedService(false)
      else if (serviceId === 'function' || serviceId === 'api' || serviceId === 'webhook') setSelectedService(serviceId)
      else {
        const serviceObject = bothServicesJson?.[serviceId] || pluginsObj?.[serviceId] || false
        setSelectedService(serviceObject)
      }
    }
  }, [serviceId, servicesData, integrationServiceLoading])

  const createFlowWithTemplate = async (template) => {
    const templateId = template?.template_id || template?.published_json_script?.identifier
    dispatch(saveScriptTemplate({ projectId, templateId })).then((e) => {
      if (e.payload?.id) {
        sendDataToParentInEmbed('initiated', e.payload.id, '', e.payload?.title, {})
        createScriptAndNavigate(e.payload.id)
      } else errorToast('Flow creation failed.')
    })
  }
  const createScriptAndNavigate = async (scriptId) => {
    const flowUrl = isEmbedUrl
      ? `/integrations/embed/${orgId}/${projectId}/${serviceId ? `service/${serviceId}/` : ''}workflow/${scriptId}/${Tabnames.DRAFT}`
      : `${config.projectsBaseUrl}/${orgId}/${projectId || `proj${orgId}`}/service/${serviceId || integrationServiceId}${
          config.workflowBaseUrl
        }/${scriptId}/${Tabnames.DRAFT}`
    navigate(flowUrl)
  }
  const handleIntegrationTriggerClick = (type, trigger) => {
    let availableObject = { trigger: '', plugin: '', function: '' }
    if (serviceType === 'action')
      availableObject = {
        plugin: integrationServiceId,
        trigger: type !== 'trigger' ? type : trigger.rowid
      }
    else {
      availableObject = {
        trigger: integrationServiceId
      }
      if (type !== 'trigger') availableObject['function'] = type
      else availableObject['plugin'] = trigger.rowid
    }
    setAvailableServices(availableObject)
    navigate(
      isEmbedUrl
        ? `/integrations/embed/${orgId}/${projectId}/service/${type === 'trigger' ? trigger?.rowid : type}`
        : `/projects/${orgId}${projectId ? `/${projectId}` : ''}/appsexplore/servicepage/${integrationServiceId}/service/${
            type === 'trigger' ? trigger?.rowid : type
          }`
    )
  }

  const createflow = async (triggerEvent, pluginEvent) => {
    const currentProjectId = projectId || `proj${orgId}`
    const triggerEventRowId = triggerEvent?.rowid
    const pluginEventRowId = pluginEvent?.rowid
    let triggerDetails
    if (triggerEventRowId) {
      if (triggerEventRowId === 'webhook')
        triggerDetails = {
          triggerType: 'webhook',
          type: 'add',
          response: {
            data: currentIntegrationSettings?.response?.value || '',
            type: 'response',
            responseType: currentIntegrationSettings?.response?.type
          }
        }
      else
        triggerDetails = {
          eventId: triggerEventRowId
        }
    }
    let functionDetails
    if (availableServices.function) {
      functionDetails = {
        type: availableServices.function
      }
    } else if (pluginEventRowId) {
      functionDetails = {
        type: 'plugin',
        eventIdArray: [pluginEventRowId]
      }
    }

    dispatch(
      saveScripts({
        project_id: currentProjectId,
        org_id: orgId,
        script: '//write your code here.',
        triggerDetails,
        functionDetails,
        isIntegration: isEmbedUrl,
        pluginData
      })
    ).then((e) => {
      const scriptId = e.payload?.id
      if (scriptId && e?.meta?.requestStatus === THUNK_CALL_STATUS.FULFILLED) {
        sendDataToParentInEmbed('initiated', scriptId, '', '', {})
        createScriptAndNavigate(scriptId)
      } else errorToast('Flow creation failed.')
    })
  }
  const serviceType = useMemo(() => {
    if (integrationServiceId !== 'webhook' && !serviceActionsArray?.length && !serviceTriggersArray?.length) return null
    if (serviceTypeFromProps !== 'both') return serviceTypeFromProps
    if (serviceActionsArray?.length === 0) return 'trigger'
    if (serviceTriggersArray?.length === 0) return 'action'
    return 'both'
  }, [serviceTypeFromProps, serviceActionsArray?.length, serviceTriggersArray?.length])
  const renderOptions = useMemo(() => {
    return (
      <Box className='column w-100  gap-5  '>
        <RenderCombinations
          trigger={availableServices.trigger}
          plugin={availableServices.plugin || availableServices.function}
          createflow={createflow}
          enabledServiceObject={enabledServiceObject}
        />
        {serviceType === 'both' && (
          <RenderCombinations
            trigger={availableServices.plugin}
            plugin={availableServices.trigger}
            createflow={createflow}
            enabledServiceObject={enabledServiceObject}
          />
        )}
      </Box>
    )
  }, [selectedService, enabledServiceObject])

  const renderServiceButton = (service, onClickHandler, startIcon) => {
    if (service.name === 'API') {
      service = {
        name: 'Custom API',
        description: 'Execute a custom API request.'
      }
    }
    if (service.name === 'Function') {
      service = {
        name: 'Custom Function',
        description: 'Run a custom JavaScript function.'
      }
    }
    return (
      <Box className='p-2 grid-item'>
        <Card variant='outlined' onClick={onClickHandler} className='service-block gap-3 column '>
          <Box className='flex-spaceBetween-center  gap-2  '>
            <Box className='flex-start-center  gap-2 '>
              <Box className='h-100'>
                <IconWrapper component={startIcon} size='32px' />
              </Box>
              <Typography className='service-title '>{service.name}</Typography>
            </Box>
          </Box>

          {service?.description && <Typography className='service-description w-100'>{service?.description}</Typography>}
        </Card>
      </Box>
    )
  }

  const renderAvailableServices = (services) =>
    services.map((service) =>
      renderServiceButton(
        service,
        () => handleIntegrationTriggerClick('trigger', service),
        <img src={service?.iconurl || ''} alt='' width='100%' height='100%' />
      )
    )
  const restrictedDomain = integrationService?.domain

  return !selectedService ? (
    <Box className='w-100 h-100vh flex-col-center-center overflow-scroll-y p-2 pb-120 pos-rel '>
      <Box className=' h-100 gap-1  flex-col-start-center preview-section-container '>
        <Breadcrumb />

        <Box className='w-100 px-2 '>
          <Box className='column gap-2'>
            <Typography variant='h4'>{heading}</Typography>
            {integrationServiceId !== 'webhook' && authenticationComponent(integrationService)}
          </Box>
          {showSubHeading && (
            <Typography>
              {pagesubheading ||
                (integrationServiceLoading ? (
                  <Skeleton />
                ) : (
                  `Enhance your ${integrationService?.name || 'webhook'} experience with a diverse range of powerful
              integrations.`
                ))}
            </Typography>
          )}
        </Box>
        <Box className='column gap-1 w-100 h-100'>
          {featuredCombinationType ? (
            featuredCombinationType === 'custom' ? (
              <CustomCombinationsComponent createflow={createflow} enabledServiceObject={enabledServiceObject} />
            ) : (
              <DefaultCombinations
                createflow={createflow}
                enabledServiceObject={enabledServiceObject}
                firstServiceId={integrationServiceId}
              />
            )
          ) : null}
          {showEnabled && <EnabledFlows firstServiceId={integrationServiceId} />}

          {(filterTrigger || filterAction) && (Object.keys(serviceTriggers || {})?.length || Object.keys(serviceActions || {})?.length) ? (
            <Box className='my-4'>
              <Typography variant='h6' className='mx-2'>
                Create flow by one of the Trigger or Action
              </Typography>
              {Object.entries({
                trigger: serviceTriggersArray,
                action: serviceActionsArray
              }).map(([title, items]) => {
                if (title === 'trigger' && !filterTrigger) return null
                if (title === 'action' && !filterAction) return null
                return (
                  items?.length > 0 && (
                    <Box key={title}>
                      <Typography variant='smallHeading' className='ml-2'>
                        {title === 'trigger' ? 'Triggers' : 'Actions'}
                      </Typography>
                      <Box className='container'>
                        {Object.values(items)?.map((action) => {
                          const enabledFlowsLength = Object.keys(enabledPluginsJsonCombinations)?.filter((combo) =>
                            combo.includes(action.rowid)
                          )?.length
                          return (
                            <Box className='p-2 grid-item' key={action.rowid}>
                              <Card
                                variant='outlined'
                                className='service-block service-block__no-description flex-spaceBetween-center gap-2'
                                onClick={() => {
                                  createflow(title === 'trigger' ? action : { rowid: 'webhook' }, title !== 'trigger' ? action : undefined)
                                }}
                              >
                                <Box className='flex-start-center gap-2'>
                                  <IconWrapper iconUrl={action.iconurl} size='32px' />
                                  <Typography className='event-box-text'>{action.name}</Typography>
                                </Box>
                                {enabledFlowsLength ? (
                                  <Box className='flex-center-center gap-1'>
                                    <Typography>{enabledFlowsLength}</Typography>
                                    <CheckCircle color='success' />
                                  </Box>
                                ) : null}
                              </Card>
                            </Box>
                          )
                        })}
                      </Box>
                    </Box>
                  )
                )
              })}
            </Box>
          ) : null}

          {showServicesBeforeEventSelection && (
            <Box className='p-2 w-100 searchbar-parent  column gap-2'>
              <Typography variant='h6'>Integrate with a Specific App</Typography>
              <PluginSearchBar
                autoFocus={mode === MiscTypes.EMBED_MODE}
                placeholder='Search services by name, description or domain '
                triggers={serviceType === 'action'}
                getServices={({ servicesArray, isLoading: loading }) => {
                  const obj = {}
                  servicesArray = servicesArray
                    .filter((service) => {
                      if (service.rowid === integrationServiceId) return false
                      const actionExists = Number(service.actioncount)
                      const triggerExists = Number(service.triggercount)
                      if (!actionExists && !triggerExists) return false
                      if (serviceType === 'action' && !triggerExists) return false
                      if (serviceType === 'trigger' && !actionExists) return false
                      return true
                    })
                    ?.slice(0, 12)
                  servicesArray.forEach((service) => {
                    obj[service.rowid] = service
                  })
                  setServicesData({
                    isLoading: loading,
                    pluginsArray: servicesArray,
                    pluginsObj: obj
                  })
                }}
              />
            </Box>
          )}
          {isLoading && (
            <Box className='container my-4'>
              {Array.from({ length: 16 }, (_, index) => index).map((el) => {
                return (
                  <Box className='grid-item p-2' key={el}>
                    <Skeleton height='200px' variant='rectangular' width='100%' />
                  </Box>
                )
              })}
            </Box>
          )}
          {showServicesBeforeEventSelection &&
            serviceType &&
            (!Object.keys(currentIntegrationSettings?.permitsingleflow || {})?.length ||
              !isEqual(currentIntegrationSettings?.permitsingleflow, enabledServiceObject?.enabledTriggers)) && (
              <Box className='w-100'>
                <Box className='container'>
                  {serviceType !== 'action' &&
                    renderServiceButton(
                      { name: 'Function' },
                      () => handleIntegrationTriggerClick('function'),
                      <JavascriptIcon className='h-100 w-100' />
                    )}
                  {serviceType !== 'action' &&
                    renderServiceButton({ name: 'API' }, () => handleIntegrationTriggerClick('api'), <ApiIcon className='h-100 w-100' />)}
                  {renderAvailableServices(pluginsArray.filter((service) => service.domain !== restrictedDomain))}
                </Box>
              </Box>
            )}

          {availableServices.trigger && showTemplates && (
            <Templates handleTemplateClick={createFlowWithTemplate} eventId={availableServices.trigger} filter='serviceId' />
          )}
          {showEnabled && <DeletedAndPausedFlows firstServiceId={integrationServiceId} />}
        </Box>
      </Box>
    </Box>
  ) : (
    <Box className='w-100 h-100 flex-col-center-center p-2 pb-120 overflow-scroll-y pos-rel '>
      <Box className=' h-100 gap-1  flex-col-start-center preview-section-container '>
        <Breadcrumb />

        {serviceId && (
          <Box className='column w-100 gap-2 px-2 '>
            <Box className='flex-start-center w-100 gap-2'>
              {selectedService?.iconurl && <img src={selectedService?.iconurl || ''} alt='' width='28px  ' />}

              <Typography variant='h4' className='header-iframe-title'>
                {selectedService ? selectedService?.name || '' : serviceId}
              </Typography>
            </Box>

            {!['api', 'function', 'webhook'].includes(serviceId) && authenticationComponent(selectedService)}
          </Box>
        )}
        {showEnabled && <EnabledFlows firstServiceId={integrationServiceId} />}

        {renderOptions}
        {availableServices.trigger && showTemplates && (
          <Templates handleTemplateClick={createFlowWithTemplate} eventId={availableServices.trigger} filter='eventId' />
        )}
      </Box>
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(PreviewSection), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.tabName,
    ParamsEnums.isTemplate,
    ParamsEnums.sectionKey,
    ParamsEnums.serviceId,
    ParamsEnums.isEmbedUrl
  ])
)
