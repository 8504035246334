import cloneDeep from 'lodash.clonedeep'
import { AuthFieldsType } from '../../../types/DHAuthentication.ts'
import { fetchUsedVariables, getInfoParamtersFromUrl } from '../../../utils/utilities'
import { OBJECTS_TO_PARSE, SECTIONS } from '../constants/developerHubConstants.ts'
import { InvocationReduxDataType } from '../../../types/Invocation.ts'

export function removeKeys(obj: any, keysToRemove: string[]) {
  keysToRemove?.forEach((keyToBeRemoved: string) => {
    if (obj[keyToBeRemoved]) {
      delete obj?.[keyToBeRemoved]
    }
  })
  return obj
}

export function removeKeysFromArrOfObject(arrOfObjects: any, keysToRemove: any) {
  if (!Array.isArray(arrOfObjects) || !Array.isArray(keysToRemove)) {
    throw new Error('Both parameters must be arrays.')
  }

  return arrOfObjects.map((obj) => {
    const newObj = { ...obj }
    keysToRemove.forEach((key) => delete newObj[key])
    return newObj
  })
}

export function convertScopesToArray(scopes: string): string[] {
  // Splitting the allScopes by comma and space.
  if (scopes?.length === 0) return []
  return scopes?.split(/[ ,]+/).filter(Boolean)
}

export function convertScopesToString(scopes: string[] | undefined, separator: string = ' '): string {
  if (!Array.isArray(scopes) || scopes.length === 0) return ''
  return scopes?.join(separator)
}

export const makeCompleteAuthUrl = (data: any) => {
  let url = data?.authrequrl

  if (url.includes('?')) {
    if (url[url.length - 1] !== '&') url += '&'
  } else {
    url += '?'
  }

  if (!url.includes('clientid')) {
    url += `client_id=${data?.clientid}&`
  }

  if (!url.includes('redirect_uri')) {
    url += `redirect_uri=${data?.redirecturl}&`
  }

  const queryParamsKeys = Object.keys(data.queryparams)

  let keyCount = 0

  queryParamsKeys.forEach((key) => {
    if (!url.includes(key) && data.queryparams[key]) {
      if (keyCount === 0) {
        url += `${key}=${data.queryparams[key]}`
      } else {
        url += `&${key}=${data.queryparams[key]}`
      }
      keyCount++
    }
  })

  return url
}

export function customObjOfFields(state: AuthFieldsType, arrayOfKeys: string[]) {
  const dataToReturn = {}
  arrayOfKeys.forEach((key) => {
    dataToReturn[key] = state?.[key]
  })
  return dataToReturn
}

export const getUsedVariableUtility = (data: any, arrayOfKeys: string[]) => {
  const dataToReturn: any = {}
  arrayOfKeys?.forEach((key) => {
    if (data?.[key]?.source) {
      dataToReturn[key] = fetchUsedVariables(data?.[key]?.source, true)
    }
  })
  return dataToReturn
}

export function parseAuthDataUtility(data: any, arrayOfKeys: string[]) {
  // @author Rudra
  const dataToReturn = cloneDeep(data)
  arrayOfKeys?.forEach((key) => {
    if (dataToReturn[key]) {
      dataToReturn[key] = JSON.parse(dataToReturn[key])
    }
  })
  return dataToReturn
}

export function stringifyAuthDataUtility(data: any) {
  // @author Rudra
  const dataToReturn = cloneDeep(data)
  const keysToStringify = Object.keys(dataToReturn).filter((key) => OBJECTS_TO_PARSE?.authKeysToParse?.includes(key))
  keysToStringify?.forEach((key) => {
    if (dataToReturn[key]) {
      dataToReturn[key] = JSON.stringify(dataToReturn[key])
    }
  })
  return dataToReturn
}

export function navigateUtility(navigate, currentPluginId, Enum) {
  const orgId = getInfoParamtersFromUrl().orgId
  // @todo import orgId from our utility function over here
  let targetUrl
  switch (Enum) {
    case SECTIONS.auth:
      targetUrl = `/developer/${orgId}/plugin/${currentPluginId}/auth?versionId=${currentPluginId}`
      break
    case SECTIONS.action:
      targetUrl = `/developer/${orgId}/plugin/${currentPluginId}/action`
      break
    case SECTIONS.trigger:
      targetUrl = `/developer/${orgId}/plugin/${currentPluginId}/trigger`
      break
    case SECTIONS.pluginDetails:
      targetUrl = `/developer/${orgId}/plugin/${currentPluginId}/pluginDetails`
      break
    default:
      targetUrl = `/developer/${orgId}`
  }

  navigate(targetUrl)
}

export function replacePathWithValue(inputString, dataObject) {
  // Use a regular expression to find placeholders ${...}
  const regexPattern = /\${(.*?)}/g

  // Use the replace method with a callback function
  const updatedString = inputString.replace(regexPattern, (match, path) => {
    // Split the path into segments
    const pathSegments = path?.split(/\.|\?\.|\?/)

    // Traverse the object to get the value based on the path segments
    let value = dataObject
    // eslint-disable-next-line
    for (const segment of pathSegments) {
      if (value === undefined || value === null) {
        // If any segment is undefined or null, return the match as is
        return match
      }
      value = value[segment]
    }

    // Return the value as the replacement
    return value !== undefined ? value : match
  })

  return updatedString
}

export function areScopesIncludedUtility(actionScopes, authDataScopes, scopesSeperatedBy) {
  // console.log(actionScopes,4543,authDataScopes)
  const seprateBy = scopesSeperatedBy === 'space' ? ' ' : ',' || ','
  const scopesToCheck = actionScopes ? actionScopes?.split(seprateBy) : []
  // let allScopes = authDataScopes?.split(seprateBy)
  // console.log(seprateBy, scopesToCheck, authDataScopes, scopesToCheck?.every((scope) => authDataScopes?.includes(scope)), "-0-")

  const areScopesIncluded = scopesToCheck?.every((scope) => authDataScopes?.includes(scope))
  if (areScopesIncluded) {
    return true
  }
  return false
}

export function maskString(inputString) {
  if (inputString?.length < 4) {
    return inputString // Not enough characters to mask
  }

  const firstTwoChars = inputString?.slice(0, 2)
  const lastTwoChars = inputString?.slice(-2)
  const middleChars = '*'?.repeat(inputString?.length - 4)

  return firstTwoChars + middleChars + lastTwoChars
}

export function getNameAfterUnderscore(str: string) {
  const match = str.match(/_(.+)/)
  if (match && match.length > 1) {
    return match[1] // Return the matched group
  }
  return null // Return null if no match found
}
export function isValidDomainName(domain) {
  const domainRegex = /^[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+$/

  return domainRegex.test(domain)
}

export function findEmptyKeysForAppendHeaders(data) {
  const keysToCheck = ['body', 'headers', 'queryParams']
  const emptyKeys = []

  keysToCheck.forEach((key) => {
    if (data[key]) {
      data[key].forEach((item) => {
        if (!item.name || !item.value) {
          emptyKeys.push(key)
        }
      })
    }
  })

  return emptyKeys.length > 0 ? emptyKeys : []
}

/**
 * Filters the authentication context by removing certain steps based on the provided slug name.
 *
 * @param {$ReduxCoreType} state - The application state object which contains the authentication context.
 * @param {string} slugName - The name of the current authentication step used to determine which subsequent steps to remove.
 *
 * @returns {Object} An object containing the filtered authentication context with all steps after the specified slug removed.
 *
 * @example
 * const state = {
 *   invocationV2: {
 *     context: {
 *       context: {
 *         authData: {
 *           configurefields: { ... },
 *           authrequrl: { ... },
 *           queryparams: { ... },
 *           Authorization: { ... },
 *           accesstokencode: { ... },
 *           refreshtokencode: { ... },
 *           revoketokencode: { ... },
 *           testcode: { ... },
 *           connectionlabel: { ... },
 *           authenticationpaths: { ... }
 *         }
 *       }
 *     }
 *   }
 * };
 * const slugName = 'Authorization';
 * const filteredContext = filterContextForAuthAi(state, slugName);
 * console.log(filteredContext);
 * // Output: { authData: { configurefields: { ... }, authrequrl: { ... }, queryparams: { ... }, Authorization: { ... } } }
 */
export function filterContextForAuthAi(invocation: InvocationReduxDataType, slugName: string) {
  const context = invocation?.context?.context?.authData
  const result = { ...context }
  const authStepsSequence = [
    'configurefields',
    'authrequrl',
    'queryparams',
    'Authorization',
    'accesstokencode',
    'refreshtokencode',
    'revoketokencode',
    'testcode',
    'connectionlabelkey',
    'connectionlabelvalue',
    'authenticationpaths'
  ]
  const slugIndex = authStepsSequence.indexOf(slugName)
  const keysToDelete = authStepsSequence.slice(slugIndex + 1)
  keysToDelete?.forEach((key) => {
    delete result[key]
  })
  return { authData: result }
}
