import cloneDeep from 'lodash.clonedeep'
import { call, put, select } from 'redux-saga/effects'
import { dhCreateApi, dhGetActionVersionCount, dhGetApi, dhUpdateApi } from '../../../api/developerHubApis/developerHubApis.ts'
import { DhOptimizeByAi } from '../../../api/developerHubApis/dhAiApis.ts'
import { errorToast, successToast } from '../../../components/customToast'
import { convertToNewStructure } from '../../../components/plugin/pluginUtils/plugin_utility.ts'
import { ACTION_TYPE, DH_FLOW_FILTERS, tableName } from '../../../pages/developerHub/constants/developerHubConstants.ts'
import { removeKeys } from '../../../pages/developerHub/utils/utility.ts'
import { $ReduxCoreType } from '../../../types/reduxCore.ts'
import actionType from '../../../types/utility.ts'
import { deleteActionStart } from '../DHActions/dhActionSlice.ts'
import {
  createNewActionVersionError,
  createNewActionVersionSuccess,
  deleteActionVersionError,
  deleteActionVersionSuccess,
  getActionVersionError,
  getActionVersionSuccess,
  optimizeCodeByAiError,
  optimizeCodeByAiSuccess,
  updateActionVersionDetailsError,
  updateActionVersionDetailsInRedux,
  updateActionVersionDetailsSuccess
} from './dhActionVersionSlice.ts'
import { sendAlert } from '../../../utils/alertUtility.ts'
import { flattenToJSON } from '../../plugin/currentSelectedPlugin/utility.ts'

interface CreateNewActionVersionPayload {
  code: any
  scopes: string
  actionid: string
  authid: string
}

interface CreateNewActionVersionAction {
  payload: CreateNewActionVersionPayload
  navigate: any
  orgId: string
  pluginId: string
}

export function* getActionVersionSaga(action: actionType<{ sectionId: string }>) {
  const { versionId } = action.urlData
  const payload = {
    ...action.payload,
    filter: DH_FLOW_FILTERS.GET_ACTION_VERSION_START,
    identifier: action.payload?.sectionId
  }
  const { isSuccess, data } = yield call(dhGetApi, payload, tableName.actionVersion)
  if (isSuccess) {
    if (!versionId) {
      const dataArray = Object.values(data)
      action.payload.setSearchParams({ versionId: dataArray?.[dataArray?.length - 1]?.rowid })
    }
    yield put(getActionVersionSuccess(data))
  } else {
    errorToast('error in getting action version details')
    yield put(getActionVersionError({}))
  }
}

export function* createNewActionVersionSaga(action: actionType<CreateNewActionVersionAction>) {
  const { payload, navigate, orgId, pluginId, setCreatingNewVersion } = action.payload
  const actionVersionCount: string = yield call(dhGetActionVersionCount, payload?.actionid)
  let dataToSend = removeKeys(cloneDeep(payload), ['type'])
  dataToSend = { ...dataToSend, versionid: `V${parseInt(actionVersionCount, 10) + 1}` }
  const { isSuccess, data } = yield call(dhCreateApi, dataToSend, tableName.actionVersion)
  if (isSuccess) {
    const { type, authversion } = yield select((state: $ReduxCoreType) => state.DHAuthentication?.[pluginId]?.[data?.authid] || {})
    let selectedData: string
    if (payload?.type === ACTION_TYPE.action) {
      selectedData = 'allActionsData'
    } else if (payload?.type === ACTION_TYPE.trigger) {
      selectedData = 'allTriggersData'
    } else {
      selectedData = 'allCustomActionData'
    }
    const {
      key,
      name,
      type: actionType,
      pluginrecordid
    } = yield select((state: $ReduxCoreType) => state?.DHAction?.[pluginId]?.[selectedData]?.[data?.actionid])
    yield put(
      createNewActionVersionSuccess({
        ...data,
        authtype: type || 'Basic',
        authversion: authversion || 'v1',
        key,
        name,
        type: actionType,
        pluginrecordid
      })
    )
    // successToast('SuccessFully Created New Action Version')
    if (setCreatingNewVersion) {
      setCreatingNewVersion(false)
    }
    if (orgId && pluginId && payload?.type && data?.actionid && data?.rowid)
      if (payload.type === ACTION_TYPE.customAction) {
        navigate(`/developer/${orgId}/plugin/${pluginId}/customAction/${data?.actionid}?versionId=${data?.rowid}`)
      } else {
        navigate(`/developer/${orgId}/plugin/${pluginId}/${payload?.type}/${data?.actionid}?versionId=${data?.rowid}`)
      }
  } else {
    errorToast('Error in creating action version')
    sendAlert(data, 'DH', 'Error while creating new action version')
    if (setCreatingNewVersion) {
      setCreatingNewVersion(false)
    }
    yield put(createNewActionVersionError({}))
  }
}

export function* updateActionVersionSaga(action: actionType<{ code: any; scopes: string }>): any {
  const { sectionId: payloadSectionId, versionId: payloadVersionId, componentToRender, setIsFieldDataSaved } = action.payload || {}
  const { sectionId: urlSectionId, versionId: urlVersionId } = action.urlData || {}

  const sectionId = payloadSectionId || urlSectionId
  const actionversionRowId = payloadVersionId || urlVersionId
  const objToUpdate = action.payload.data || action?.payload
  const payload = {
    dataToUpdate: { ...objToUpdate },
    filter: DH_FLOW_FILTERS.UPDATE_ACTION_VERSION_DETAILS_START,
    identifier: actionversionRowId
  }
  const { isSuccess, data } = yield call(dhUpdateApi, payload, tableName.actionVersion)
  if (isSuccess) {
    yield put(updateActionVersionDetailsSuccess({ data, sectionId, versionId: actionversionRowId }))
    if (setIsFieldDataSaved) {
      setIsFieldDataSaved((prev) => ({ ...prev, [componentToRender]: true }))
    }
  } else {
    errorToast('error in updating actions')
    yield put(updateActionVersionDetailsError({ sectionId, versionId: actionversionRowId }))
  }
}

export function* setActionVersionDetailSaga() {
  // yield put(setActionVersionDetailStart(null))
}

export function* deleteActionVersionSaga(
  action: actionType<{ data: { rowid: string; type: 'action' | 'trigger' }; navigate: () => void }>
) {
  const payload = {
    dataToUpdate: { isdeleted: true },
    filter: DH_FLOW_FILTERS.DELETE_ACTION_VERSION_START,
    identifier: action.payload?.data?.rowid
  }
  const { isSuccess } = yield call(dhUpdateApi, payload, tableName.actionVersion)
  if (isSuccess) {
    yield put(
      deleteActionVersionSuccess({
        data: { rowid: action.payload?.data.rowid, type: action.payload.data.type },
        navigate: action.payload.navigate ? action.payload.navigate : () => {}
      })
    )
    successToast('Deleted Action Version Successfully')
  } else {
    yield put(deleteActionVersionError({ rowid: action.payload?.data?.rowid }))
    errorToast('Some erro while deleting action version')
  }
}

export function* deteleActionVersionSuccessSaga(
  action: actionType<{ data: { rowid: string; type: 'action' | 'trigger' }; navigate: () => void }>
) {
  const previousVersions = yield select((state: $ReduxCoreType) => state.DHActionVersion?.[action.urlData?.sectionId])
  const previousVersionsArr = Object.keys(previousVersions)
  if (previousVersionsArr?.length === 0) {
    yield put(
      deleteActionStart({
        rowid: action.urlData?.sectionId,
        type: action.payload.data.type
      })
    )
  }
  if (action.payload.navigate) {
    action.payload.navigate(`/developer/${action.urlData.orgId}/plugin/${action.urlData.pluginId}`)
  }
}

export function* optimzeDhCodeByAiSaga(
  action: actionType<{
    apiEnum: 'perform' | 'performsubscribe' | 'performunsubscribe' | 'inputjson'
    code: string
  }>
) {
  const { orgId, pluginId, sectionId, versionId, sectionKey } = action.urlData
  const { apiEnum, code } = action.payload
  try {
    const keyToGetDataFrom: 'allActionsData' | 'allTriggersData' = sectionKey === ACTION_TYPE?.action ? 'allActionsData' : 'allTriggersData'
    const { pluginName, actionOrTriggerName, pluginInputDataContext } = yield select((state: $ReduxCoreType) => ({
      pluginName: state?.DHPlugin?.[orgId]?.[pluginId].name,
      actionOrTriggerName: state?.DHAction?.[pluginId]?.[keyToGetDataFrom]?.[sectionId]?.name,
      context: state?.invocationV2?.[sectionId]?.context,
      pluginInputDataContext: state?.stepsDataV3?.[sectionId]?.[versionId]?.draft?.pluginData.selectedValues
    }))
    const flattenJson = {
      context: { authData: pluginInputDataContext?.authData, inputData: flattenToJSON(cloneDeep(pluginInputDataContext?.inputData)) }
    }
    const optimizedcode = yield call(DhOptimizeByAi, {
      apiEnum,
      code,
      pluginName,
      actionOrTriggerName,
      context: flattenJson
    })
    const data =
      apiEnum === 'inputjson'
        ? {
            inputjson: { ...convertToNewStructure(optimizedcode?.inputFields), inputFields: optimizedcode?.inputFields },
            perform: optimizedcode?.perform || ''
          }
        : { optimizedCode: { [apiEnum]: optimizedcode } }
    yield put(
      updateActionVersionDetailsInRedux({
        data: data,
        sectionId,
        versionId
      })
    )
    yield put(
      optimizeCodeByAiSuccess({
        sectionId,
        versionId,
        apiEnum
      })
    )
  } catch (error) {
    console.log(error)
    yield put(
      updateActionVersionDetailsError({
        sectionId,
        versionId
      })
    )
    yield put(
      optimizeCodeByAiError({
        apiEnum,
        sectionId,
        versionId
      })
    )
  }
}
