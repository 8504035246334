import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useEffect } from 'react'
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useLocation, useNavigate } from 'react-router-dom'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import './Notification.scss'
import { createApiKey, getApiKeyByName, getNotificationEmbedToken } from '../../api/index'
import { ParamsEnums } from '../../enums'
import config from '../../config'

function NotificationSlider({ orgId }) {
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      if (!location.pathname.includes('notification')) return
      const authkey = await getAuthKey()
      const script = document.createElement('script')
      script.id = 'viasocket-embed-main-script'
      script.src = process.env.REACT_APP_EMBED_HOSTED_FILE_URL
      script.setAttribute('parentId', 'notification-embed-parent')
      const { serviceData, token } = await getNotificationEmbedToken(authkey)
      script.setAttribute('embedToken', token)
      script.setAttribute('serviceData', serviceData)

      document.body.appendChild(script)
    }
    fetchData()
    return () => {
      const existingScript = document.getElementById('viasocket-embed-main-script')
      if (existingScript) document.body.removeChild(existingScript)
    }
  }, [orgId, location.pathname])

  async function getAuthKey() {
    const data = await getApiKeyByName('viasocket_notification')
    let authkey = null
    if (data && Array.isArray(data)) {
      data.forEach((element) => {
        if (element.name === 'viasocket_notification') {
          authkey = element?.authkey
        }
      })
    } else {
      console.error('Data is undefined or not an array:', data)
      // Handle the case where data is not as expected
    }
    if (authkey == null) authkey = (await createApiKey('viasocket_notification'))?.authkey
    return authkey
  }

  return (
    <ListItem
      disablePadding
      onClick={(e) => {
        e.stopPropagation()
        navigate(`${config.orgBaseUrl}/${orgId}/notification`)
      }}
      className={`${location.pathname.includes('notification') ? 'selected-list-item' : 'accordion-list-item mb-3 '}`}
    >
      <ListItemButton>
        <ListItemIcon className='lists__icon'>
          <AddAlertOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary='Notifications' />
        <ChevronRightIcon className='transform-8px' />
      </ListItemButton>
    </ListItem>
  )
}
export default React.memo(addUrlDataHoc(React.memo(NotificationSlider), [ParamsEnums.orgId]))
