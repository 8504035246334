import JavascriptIcon from '@mui/icons-material/Javascript'
import { Box, Skeleton, Typography } from '@mui/material'
import React from 'react'
import ApiIcon from '@mui/icons-material/Api'
import { MiscTypes, ParamsEnums } from '../../../enums'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import { useFetchActionsTriggers } from '../../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import IconWrapper from '../../IconWrapper/IconWrapper.tsx'
import RenderCombinationComponents from './RenderCombinationComponents.tsx'

function RenderCombinations({
  trigger,
  plugin,
  orgId,
  projectId,
  createflow,
  enabledServiceObject = {},
  eventId,
  serviceId,
  chooseCombinations = false
}) {
  const { currentIntegrationSettings, eventsNotallowedMultiple, pluginEventsAllowed, triggerEventsAllowed } = useCustomSelector((state) => {
    const filteredServices =
      (state.appInfo.mode === MiscTypes.EMBED_MODE
        ? state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings?.config?.filteredServices
        : state.appInfo.localConfiguration?.filteredServices) || {}
    return {
      currentIntegrationSettings: state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings || {},
      eventsNotallowedMultiple: state.projects.embedProject?.[orgId]?.['active']?.[projectId]?.settings?.permitsingleflow || {},
      pluginEventsAllowed: Object.keys(filteredServices?.[plugin] || {}),
      triggerEventsAllowed: Object.keys(filteredServices?.[trigger] || {})
    }
  })

  const isCustom = ['api', 'function'].includes(plugin)

  const { data: pluginEventObject, isLoading: pluginEventsLoading } = useFetchActionsTriggers(plugin, 'actions', pluginEventsAllowed) || {}
  const { data: triggerEventObject, isLoading: triggerEventsLoading } =
    useFetchActionsTriggers(trigger, 'trigger', triggerEventsAllowed) || {}
  const localPluginEventObject = isCustom ? { [plugin]: { name: plugin, rowid: plugin } } : pluginEventObject
  const localTriggerEventObject = trigger === 'webhook' ? { webhook: { name: 'webhook', rowid: 'webhook' } } : triggerEventObject
  let pluginEventList = Object.values(localPluginEventObject || {})
  let triggerEventList = Object.values(localTriggerEventObject || {})
  // Define a helper function to filter the event lists based on the integration settings
  pluginEventList =
    pluginEventList.filter(
      (obj) =>
        currentIntegrationSettings?.serviceId !== plugin ||
        (currentIntegrationSettings.permittedEvents === 'all' && !currentIntegrationSettings.unpermittedEvents.includes(obj.rowid)) ||
        (currentIntegrationSettings.permittedEvents !== 'all' && currentIntegrationSettings.permittedEvents.includes(obj.rowid))
    ) || []
  triggerEventList =
    triggerEventList.filter(
      (obj) =>
        currentIntegrationSettings?.serviceId !== trigger ||
        (currentIntegrationSettings.permittedEvents === 'all' && !currentIntegrationSettings.unpermittedEvents.includes(obj.rowid)) ||
        (currentIntegrationSettings.permittedEvents !== 'all' && currentIntegrationSettings.permittedEvents.includes(obj.rowid))
    ) || []
  const triggerIcon = () => {
    return trigger !== 'webhook' ? <IconWrapper iconUrl={triggerEventList?.[0]?.iconurl} size='32px' /> : null
  }

  const pluginIconUrl = () => {
    let icon = null
    if (plugin === 'function') icon = <JavascriptIcon className='h-100 w-100 ' />
    else if (plugin === 'api') icon = <ApiIcon className='h-100 w-100 ' />
    else return <IconWrapper iconUrl={pluginEventList?.[0]?.iconurl} size='32px' />
    return <IconWrapper component={icon} size='32px' />
  }

  if (eventId && triggerEventList.find((trigger) => trigger.rowid === eventId))
    triggerEventList = triggerEventList.filter((trigger) => trigger.rowid === eventId)
  if (eventId && pluginEventList.find((action) => action.rowid === eventId))
    pluginEventList = pluginEventList.filter((action) => action.rowid === eventId)

  return pluginEventsLoading || triggerEventsLoading ? (
    <Box className='container pt-2 '>
      {Array.from({ length: 4 }, (_, index) => index).map((el) => {
        return (
          <Box className='grid-item p-2' key={el}>
            <Skeleton height='200px' variant='rectangular' width='100%' />
          </Box>
        )
      })}
    </Box>
  ) : (
    <Box className='column w-100 gap-2'>
      {pluginEventList.length === 0 || triggerEventList.length === 0
        ? null
        : triggerEventList?.length <= pluginEventList?.length
        ? triggerEventList?.map((triggerEvent) => {
            return (
              <Box className='pb-4 w-100 column' key={triggerEvent.rowid}>
                <Box className='flex-start-center p-2 gap-2'>
                  {triggerEvent.name !== 'webhook' ? (
                    <Typography>
                      When <strong>{triggerEvent.name}</strong> to {triggerEvent?.pluginname}
                    </Typography>
                  ) : null}
                </Box>
                <Box className='container pt-2 '>
                  {plugin &&
                    pluginEventList?.map((pluginEvent) => {
                      if (
                        eventsNotallowedMultiple?.[triggerEvent?.rowid] &&
                        enabledServiceObject?.['enabledTriggers']?.[triggerEvent?.rowid]
                      )
                        return null
                      let eventObj = serviceId === triggerEvent.pluginrecordid ? triggerEvent : pluginEvent
                      const combinationsAlwaysOpen = ['webhook', 'api', 'function'].includes(serviceId)
                      if (serviceId === 'webhook') eventObj = pluginEvent
                      else if (serviceId === 'function' || serviceId === 'api') eventObj = triggerEvent
                      const dataToshow = (
                        <Box className='gap-3 column w-100'>
                          <Box className='flex-start-center gap-2'>
                            {triggerEvent.name !== 'webhook' && triggerIcon()}
                            {pluginIconUrl()}
                          </Box>
                          <Typography className='service-description'>
                            {isCustom ? (
                              <>
                                Custom <strong>{pluginEvent.name}</strong>
                              </>
                            ) : (
                              <>
                                <strong>{pluginEvent.name}</strong> in {pluginEvent.pluginname}
                              </>
                            )}
                            {triggerEvent.name !== 'webhook' && (
                              <>
                                {' when '}
                                {triggerEvent.name} in {triggerEvent.pluginname}
                              </>
                            )}
                          </Typography>
                        </Box>
                      )
                      return (
                        <RenderCombinationComponents
                          key={`${triggerEvent?.rowid}_${pluginEvent.rowid}`}
                          createflow={createflow}
                          eventObj={eventObj}
                          dataToshow={dataToshow}
                          triggerEvent={triggerEvent}
                          eventNotallowed={eventsNotallowedMultiple?.[triggerEvent?.rowid]}
                          pluginEvent={pluginEvent}
                          chooseCombinations={chooseCombinations}
                          combinationsAlwaysOpen={combinationsAlwaysOpen}
                        />
                      )
                    })}
                </Box>
              </Box>
            )
          })
        : pluginEventList?.map((pluginEvent) => {
            return (
              <Box className='pb-4 w-100 column' key={pluginEvent.rowid}>
                <Box className='flex-start-center p-2 gap-2'>
                  <Typography>
                    {isCustom ? (
                      `Custom ${pluginEvent.name}`
                    ) : (
                      <>
                        <strong>{pluginEvent.name}</strong> in {pluginEvent?.pluginname}
                      </>
                    )}
                  </Typography>
                </Box>
                <Box className='container pt-2 '>
                  {trigger &&
                    triggerEventList?.map((triggerEvent) => {
                      if (
                        eventsNotallowedMultiple?.[triggerEvent?.rowid] &&
                        enabledServiceObject?.['enabledTriggers']?.[triggerEvent?.rowid]
                      )
                        return null

                      let eventObj = serviceId === triggerEvent.pluginrecordid ? triggerEvent : pluginEvent
                      if (serviceId === 'webhook') eventObj = pluginEvent
                      else if (serviceId === 'function' || serviceId === 'api') eventObj = triggerEvent
                      const combinationsAlwaysOpen = ['webhook', 'api', 'function'].includes(serviceId)

                      const dataToshow = (
                        <Box className='gap-3 column w-100'>
                          <Box className='flex-start-center gap-2'>
                            {triggerEvent.name !== 'webhook' && triggerIcon()}
                            {pluginIconUrl()}
                          </Box>
                          <Typography className='service-description'>
                            {triggerEvent.name !== 'webhook' && (
                              <>
                                {' when '}
                                <strong>{triggerEvent.name}</strong>
                                {' in '}
                                {triggerEvent.pluginname}
                                {' perform '}
                              </>
                            )}
                            {isCustom ? `Custom ${pluginEvent.name}` : `${pluginEvent.name} in ${pluginEvent?.pluginname}`}
                          </Typography>
                        </Box>
                      )
                      return (
                        <RenderCombinationComponents
                          key={`${triggerEvent?.rowid}_${pluginEvent.rowid}`}
                          createflow={createflow}
                          eventObj={eventObj}
                          dataToshow={dataToshow}
                          triggerEvent={triggerEvent}
                          eventNotallowed={eventsNotallowedMultiple?.[triggerEvent?.rowid]}
                          pluginEvent={pluginEvent}
                          chooseCombinations={chooseCombinations}
                          combinationsAlwaysOpen={combinationsAlwaysOpen}
                        />
                      )
                    })}
                </Box>
              </Box>
            )
          })}
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(RenderCombinations), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.tabName,
    ParamsEnums.isTemplate,
    ParamsEnums.sectionKey,
    ParamsEnums.eventId,
    ParamsEnums.serviceId
  ])
)
