import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, Card, Chip } from '@mui/material'
import { useDispatch } from 'react-redux'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from '../../../enums'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import { updateAppInfoState } from '../../../store/appInfo/appInfoSlice.ts'

function RenderCombinationComponents({ dataToshow, createflow = () => {}, triggerEvent, pluginEvent, chooseCombinations = false }) {
  const { localConfig } = useCustomSelector((state) => ({
    localConfig: state.appInfo.localConfiguration
  }))
  const dispatch = useDispatch()
  return (
    <Box key={pluginEvent?.rowid} className='p-2 grid-item'>
      <Card
        variant='outlined'
        onClick={() => {
          if (chooseCombinations) {
            const featuredCombinationsDummy = { ...localConfig?.featuredCombinations }
            const oldValue = featuredCombinationsDummy[`${triggerEvent?.rowid}_${pluginEvent?.rowid}`]
            if (oldValue) delete featuredCombinationsDummy[`${triggerEvent?.rowid}_${pluginEvent?.rowid}`]
            else featuredCombinationsDummy[`${triggerEvent?.rowid}_${pluginEvent?.rowid}`] = true
            dispatch(updateAppInfoState({ localConfiguration: { ...localConfig, featuredCombinations: featuredCombinationsDummy } }))
            return
          }
          createflow(triggerEvent, pluginEvent)
        }}
        className=' service-block gap-3 column pos-rel '
      >
        {dataToshow}
        {chooseCombinations && localConfig?.featuredCombinations?.[`${triggerEvent?.rowid}_${pluginEvent?.rowid}`] ? (
          <Box className='sticky-top-right flex-end-center gap-1'>
            <CheckCircleIcon color='success' />
          </Box>
        ) : (
          <Box className='sticky-top-right flex-end-center gap-1'>
            <Chip label='Add' className='opacity-0' variant='outlined' />
          </Box>
        )}
      </Card>
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(RenderCombinationComponents), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.tabName,
    ParamsEnums.isTemplate,
    ParamsEnums.sectionKey,
    ParamsEnums.eventId,
    ParamsEnums.serviceId
  ])
)
