import { SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit'
import cloneDeep from 'lodash.clonedeep'
import { PluginFields } from '../../../types/DHPlugin.ts'
import { $DHPluginReduxType } from '../../../types/reduxCore.ts'
import actionType from '../../../types/utility.ts'

const samplePluginData: PluginFields = {
  rowid: '',
  pluginid: '',
  createdat: '',
  createdby: '',
  updatedat: '',
  updatedby: '',
  name: '',
  description: '',
  domain: '',
  service_url: '',
  audience: 'Private',
  category: [],
  orgid: '',
  status: 'unpublished',
  publishdescription: '',
  isLoading: false,
  whitelistdomains: [],
  customactionconfigs: '',
  authrecordid: '',
  istriggeravailable: false,
  iconurl: '',
  brandcolor: ''
}
let previousStateData = { ...samplePluginData }
export const initialState: $DHPluginReduxType = {}

export const reducers: ValidateSliceCaseReducers<$DHPluginReduxType, SliceCaseReducers<$DHPluginReduxType>> = {
  getAllPluginStart(state, action) {
    const { orgId } = action.urlData
    if (!state[orgId]) {
      state[orgId] = {}
    }
    state.isLoading = true
  },

  getAllPluginSuccess(state, action) {
    const { orgId } = action.urlData
    const tempMapping: { [key: string]: PluginFields } = {}
    action?.payload?.forEach((plugData: PluginFields) => {
      tempMapping[plugData?.rowid] = plugData
    })
    state[orgId] = { ...state[orgId], ...tempMapping }
    state.isLoading = false
  },

  getAllPluginError(state) {
    state.isLoading = false
  },

  getPluginDetailStart(state, action: actionType<PluginFields[]>) {
    const orgId = action?.urlData?.orgId
    if (!state[orgId]) {
      state[orgId] = {}
    }

    const rowId = action?.payload?.rowid
    if (!state[orgId][rowId]) {
      state[orgId][rowId] = { isLoading: false }
    }

    state[orgId][rowId].isLoading = true
  },

  getPluginDetailSuccess(state, action: actionType<PluginFields[]>) {
    const { orgId } = action?.urlData
    const pluginId = action?.payload[0]?.rowid
    state[orgId][pluginId] = { ...action.payload[0], isLoading: false }
    // state.isLoading = false
  },
  getPluginDetailError(state, action) {
    const { orgId } = action?.urlData
    state[orgId][action?.payload?.rowid].isLoading = false
    // state.isLoading = false
  },

  updatePluginDetailsStart(state, action: actionType<PluginFields>) {
    const { orgId } = action?.urlData
    let pluginId = action.urlData?.pluginId
    if (action.payload.rowid) {
      pluginId = action.payload.rowid
    }
    if (!state[orgId]) {
      state[orgId] = {} // Initialize if not exist
    }
    if (!state[orgId][pluginId]) {
      state[orgId][pluginId] = { ...samplePluginData } // Initialize if not exist
    }
    previousStateData = cloneDeep(state[orgId][pluginId])
    state[orgId][pluginId] = { ...state[orgId][pluginId], ...action.payload, isLoading: true }
  },

  updatePluginDetailsSuccess(state, action: actionType<PluginFields>) {
    const { orgId } = action?.urlData
    let pluginId = action.urlData.pluginId
    if (action.payload.rowid) {
      pluginId = action.payload.rowid
    }
    if (!state[orgId]) {
      state[orgId] = {}
    }
    if (!state[orgId][pluginId]) {
      state[orgId][pluginId] = { ...samplePluginData }
    }
    state[orgId][pluginId] = { ...state[orgId][pluginId], isLoading: false }
    previousStateData = samplePluginData
  },

  updatePluginDetailsError(state, action: actionType<PluginFields>) {
    const { orgId } = action?.urlData
    let pluginId = action.urlData.pluginId
    if (action.payload.rowid) {
      pluginId = action.payload.rowid
    }
    if (!state[orgId]) {
      state[orgId] = {}
    }
    if (!state[orgId][pluginId]) {
      state[orgId][pluginId] = { ...samplePluginData }
    }
    state[orgId][pluginId] = { ...previousStateData, isLoading: false }
  },

  createNewPluginStart(state, action: actionType<{ payload: { name: string; orgid: string } }>) {
    const { payload } = action.payload
    const { orgId } = action.urlData
    state.isAiResponsePending = true
    state[orgId] = { ...state[orgId], newPluginSampleId: { ...samplePluginData, ...payload, isLoading: true } }
  },

  createNewPluginSuccess(state, action: actionType<PluginFields>) {
    const { orgId } = action.urlData
    delete state[orgId]['newPluginSampleId']
    state[orgId][action.payload.rowid] = { ...action.payload, isLoading: false, isAiResponsePending: true }
  },
  createNewPluginError(state, action) {
    const { orgId } = action?.urlData
    delete state[orgId]['newPluginSampleId']
    state[orgId][action.payload.rowid] = { isLoading: false, isAiResponsePending: false }
  },
  deletePluginStart(state, action) {
    const { orgId } = action?.urlData
    state[orgId][action.payload.pluginId] = { ...state[orgId][action?.payload?.pluginId], isLoading: true }
  },
  deletePluginSuccess(state, action) {
    const { orgId } = action?.urlData
    delete state[orgId][action.payload.pluginId]
  },
  deletePluginError(state, action) {
    const { orgId } = action?.urlData
    state[orgId][action.payload.pluginId] = { ...action.payload, isLoading: false }
  },
  updatePluginDetailsInRedux(state, action: actionType<PluginFields>) {
    const orgId = action.urlData?.orgId
    state[orgId][action.payload?.rowid || action.urlData.pluginId] = {
      ...state[orgId][action.payload?.rowid || action.urlData.pluginId],
      ...action.payload,
      isAiResponsePending: false
    }
  }
}
