import { useNavigate } from 'react-router-dom'
import { Box, Button, CircularProgress, Divider } from '@mui/material'
import cloneDeep from 'lodash.clonedeep'
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import NotificationApiService from '../../api/notificationApi.ts'
import {
  BlockTypes,
  CUSTOM_PLUGIN,
  LOCAL_NOTFICATION_REQUIRED_FIELDS,
  LOCAL_NOTFICATION_UPDATE,
  MiscTypes,
  ParamsEnums,
  ReservedKeywords,
  STEP_OPERATION_STATUS,
  Tabnames
} from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useFetchPlugins } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { filterObjectByKey } from '../../store/invocationV2/invocationSelectorV2'
import { handleAddStepsV2, handleDeleteStepsV2 } from '../../store/invocationV2/invocationSliceV2.ts'
import { cancelNotification, setLocalNotification } from '../../store/notification/notificationSlice'
import { getPostDataForApiPayload } from '../../store/stepsV2/stepSelectorV2.ts'
import { askAiForFunctionThunk, createOrUpdateFunctionThunk } from '../../store/stepsV2/stepThunkV2'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { extractDomainWithoutWWW, getStringToArrayObject, replaceSpacesWithUnderscores, urlWithParams } from '../../utils/utilities'
import { errorToast, warningToast } from '../customToast'
import {
  getContextFromInvocation,
  getDuplicateKeysArrFordictionary,
  isRequiredFieldsHaveValueTrueRecursion,
  removeGarbageDataFromSelectedValues
} from '../plugin/pluginUtils/plugin_utility.ts'
import { createOrUpdateTriggerThunk } from '../../store/flowJson/flowJsonThunkV2.ts'
import { setKeyValueInstance } from '../../store/stepsV2/stepSliceV3.ts'
import { updateDraftedFlowJsonTrigger } from '../../store/flowJson/flowJsonSliceV2.ts'
import { store } from '../../store/index'

type SaveButtonV3Props = {
  orgId: string
  projectId: string
  scriptId: string
  stepId: string
  slugName: string
  setSearchParams: (params: any) => void
  tabName: string
  additionalFunction: () => any
}

/**
 * @param {object} root0 - props object
 * @param {string} root0.orgId - The organization ID
 * @param {string} root0.projectId - The project ID
 * @param {string} root0.scriptId - The script ID
 * @param {string} root0.stepId - The step ID
 * @param {string} root0.slugName - The slug name
 * @param {Function} root0.setSearchParams - Function to set search parameters
 * @param {string} root0.tabName - The tab name
 * @param {Function} root0.additionalFunction - Additional function to be executed
 * @returns {void} - jsx element
 */
function SaveButtonV3({
  orgId,
  projectId,
  scriptId,
  stepId,
  slugName,
  setSearchParams,
  tabName,
  additionalFunction = () => {}
}: SaveButtonV3Props) {
  // save button for all the steps

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    flowJson,
    stepInstance,
    authId,
    actionId,
    pluginId,
    steps,
    context,
    notificationId,
    dictionaryKeysWithSameValue,
    stepsInvocationData,
    stepsPayloadData,
    valsInvocationData,
    hasUnsavedCode,
    stepStatus,
    invocationContext,
    inputData,
    blocks,
    triggerStepType
  } = useCustomSelector((state: $ReduxCoreType) => {
    const stepData = state.stepsDataV3?.[scriptId]?.[stepId]?.[tabName]?.pluginData || {}
    const invocationData = state.invocationV2?.[scriptId]?.invocationData || {}
    return {
      flowJson: state?.flowJsonV2?.[scriptId]?.flowJson,
      stepInstance: state.stepsDataV3?.[scriptId]?.[stepId]?.[tabName],
      authId: stepData?.selectedValues?.authData?.id,
      actionId: stepData?.actionVersionId,
      pluginId: stepData?.serviceId,
      steps: stepData?.inputJson?.steps,
      blocks: stepData?.inputJson?.blocks,
      context: filterObjectByKey(state, slugName, scriptId),
      notificationId: state?.notification?.localNotification?.[`${stepId}.${LOCAL_NOTFICATION_UPDATE.address}`]?.notificationId,
      dictionaryKeysWithSameValue: getDuplicateKeysArrFordictionary(stepData),
      stepsInvocationData: invocationData?.responseSnapshot?.[slugName],
      valsInvocationData: invocationData?.vals?.[slugName],
      stepsPayloadData: invocationData?.stepRequestSnapshot?.[slugName],
      hasUnsavedCode: state?.stepsDataV3?.[scriptId]?.[stepId]?.hasUnsavedCode,
      isChatBotCase:
        state.appInfo.mode === MiscTypes.EMBED_MODE
          ? state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings?.config?.chatbot
          : state.appInfo.localConfiguration?.chatbot,
      stepStatus: state.flowJsonV2?.[scriptId]?.flowJson?.blocks?.[slugName]?.status,
      invocationContext: getContextFromInvocation(invocationData),
      inputData: stepData?.selectedValues?.inputData || {},
      triggerStepType: state.appInfo.currentStepType
    }
  })
  const whiteListDomainsForCustomPluginApi = useFetchPlugins(orgId, [], [pluginId])?.data?.[pluginId]?.whitelistdomains || []
  const isPublishedTab = tabName === Tabnames.PUBLISH
  const type = stepId === 'CRON_PRE_PROCESS' ? triggerStepType : flowJson?.blocks?.[slugName]?.type
  const currentCodeRef = useRef(stepInstance?.code)
  const hasToBeDisabled = stepStatus === STEP_OPERATION_STATUS.DELETE

  useEffect(() => {
    currentCodeRef.current = stepInstance?.code
  }, [stepInstance?.code])

  const saveFunction = async () => {
    if (type === 'plugin' && dictionaryKeysWithSameValue?.keysArr?.length) {
      errorToast(`keys with same ${dictionaryKeysWithSameValue?.errorMessage} not allowed`)
      return
    }
    if (
      type === 'plugin' &&
      !isRequiredFieldsHaveValueTrueRecursion(
        stepInstance?.requiredFieldsList,
        stepInstance?.flags,
        inputData,
        invocationContext,
        steps,
        blocks
      )
    ) {
      dispatch(setLocalNotification({ [`${stepId}.${LOCAL_NOTFICATION_REQUIRED_FIELDS.address}`]: { show: true } }))
      return
    }
    let code
    let dataToSend: any
    const status = flowJson?.blocks?.[slugName]?.status
    if (type === 'plugin') {
      const cloneDeepObj = cloneDeep(stepInstance.pluginData.selectedValues)
      const newInputData = removeGarbageDataFromSelectedValues(cloneDeepObj.inputData, steps, blocks, invocationContext)
      cloneDeepObj.inputData = newInputData
      code = {
        ...stepInstance?.pluginData,
        selectedValues: cloneDeepObj
      }
      if (flowJson?.blocks?.[slugName]?.iconUrl) {
        // on update plugin we take url from flow json
        code.iconUrl = flowJson?.blocks?.[slugName]?.iconUrl
      }
    } else if (type === BlockTypes.API) {
      code = createCodeForAPI(stepInstance)
    } else if (type === BlockTypes.VARIABLE || type === BlockTypes.IFBLOCK) {
      code = stepInstance?.code
    } else if (type === BlockTypes?.FUNCTION) {
      code = currentCodeRef?.current || stepInstance?.code
    }
    let title = replaceSpacesWithUnderscores(stepInstance?.title)
    if (
      title?.toLowerCase()?.includes('untitled') &&
      (type === BlockTypes.API || type === BlockTypes.FUNCTION) &&
      status === STEP_OPERATION_STATUS.DRAFTED
    ) {
      try {
        title = JSON.parse(
          (
            await axios.post(
              'https://routes.msg91.com/api/proxy/1258584/29gjrmh24/api/v2/model/chat/completion',
              { user: `suggest me a name for - ${JSON.stringify({ type, code })}`, bridge_id: '66d156b7190e100685297d44' },
              { headers: { pauthkey: '5873b66600c945016d8d677634b4ed69' } }
            )
          )?.data?.response?.data?.content
        )?.name
        dispatch(setKeyValueInstance({ title }))
      } catch (error) {
        console.error('Error in creatin new title via ai', error)
      }
    }
    const res = validateFunction(title, type, stepId, code)
    if (!res) return

    if ([BlockTypes.API, BlockTypes.FUNCTION, BlockTypes.PLUG].includes(type)) {
      dataToSend = {
        type,
        code,
        title,
        org_id: orgId,
        project_id: projectId,
        auth_id: authId,
        action_id: authId ? actionId || CUSTOM_PLUGIN.id : null,
        stepId,
        variables: context,
        dynamicVariables: stepInstance?.dynamicVariables
      }
    } else if (type === BlockTypes.VARIABLE) {
      dataToSend = {
        type,
        title,
        data: stepInstance?.code,
        html: stepInstance?.codeHTML,
        stepId
      }
    } else if (type === BlockTypes.IFBLOCK) {
      let additionalResponse = {}
      if (!stepInstance?.statement) {
        additionalResponse = await additionalFunction()
      }
      dataToSend = {
        type,
        condition: additionalResponse?.code || stepInstance?.code,
        html: additionalResponse?.html || stepInstance?.codeHTML,
        statement: additionalResponse?.statement || stepInstance?.statement,
        stepId,
        title: additionalResponse?.title || stepInstance?.title
      }
    } else if (type === BlockTypes.RESPONSE) {
      const datatoappend = await additionalFunction()
      dataToSend = {
        ...datatoappend,
        variables: context
      }
    }
    if (status === STEP_OPERATION_STATUS.DELETE) {
      dataToSend = { ...dataToSend, status: STEP_OPERATION_STATUS.ACTIVE }
    }
    // title validation for reserved keywords

    if (stepId === 'CRON_PRE_PROCESS') {
      dispatch(
        updateDraftedFlowJsonTrigger({
          triggerAdvanceConfigAction: 'UPDATE',
          triggerAdvanceConfigType: 'preProcess',
          triggerAdvanceConfig: dataToSend
        })
      )
      const triggerInfo = store.getState()?.flowJsonV2?.[scriptId]?.draftedFlowJson?.trigger
      dispatch(createOrUpdateTriggerThunk({ ...triggerInfo, type: 'add', navigate: () => {}, preProcess: dataToSend })).then((e) => {
        if (e.meta?.requestStatus === 'fulfilled') {
          setSearchParams({})
        }
      })
      return
    }
    await dispatch(createOrUpdateFunctionThunk({ data: dataToSend })).then((e) => {
      // this is temporary fix for handling the fulfiled state it will be covered everywhere jaha thunk use hora he in the extra reducers not here
      if (e.meta?.requestStatus === 'fulfilled') {
        setSearchParams({})
        // dispatch(setFlowAndDraftFlowJson({ ...e?.payload?.script?.json_script }))

        // if custom repsonse, then remove delay
        if (
          flowJson?.trigger?.triggerType === 'webhook' &&
          flowJson?.trigger?.delay &&
          dataToSend?.type === 'response' &&
          dataToSend?.responseType === 'custom'
        ) {
          dispatch(createOrUpdateTriggerThunk({ ...flowJson?.trigger, type: 'add', delay: null, navigate }))
        }
        if (type === 'plugin' && stepId && notificationId) {
          NotificationApiService.markNotificationAsRead(notificationId)
          dispatch(cancelNotification(`${stepId}.${LOCAL_NOTFICATION_UPDATE.address}`))
        }
        // setSearchParams({ stepId: stepId, slugName: e.error ? slugName : dataToSend?.title })
        if ([BlockTypes.FUNCTION, BlockTypes.API, BlockTypes.PLUG, BlockTypes.VARIABLE].includes(type)) {
          const data = {
            payload: {
              key: dataToSend?.title,
              value: type === BlockTypes.VARIABLE ? valsInvocationData : stepsInvocationData,
              ...(type === BlockTypes.API ? { requestConfig: stepsPayloadData } : {})
            },
            type
          }
          dispatch(handleAddStepsV2(data))
          if (dataToSend?.title !== slugName) {
            dispatch(handleDeleteStepsV2({ payload: { key: slugName }, type }))
          }
        }
      }
    })
    setLoading(false)
  }

  const validateFunction = (name, type, id, code) => {
    if (type === BlockTypes.RESPONSE) return true
    if (type === BlockTypes.IFBLOCK) {
      if (!code) {
        warningToast(`${type} can't be empty.`)
        return false
      }
    }
    const minNameLength = 4
    const maxNameLength = 50
    const pattern = /^[a-zA-Z0-9_]+$/
    if (slugName === 'CRON_PRE_PROCESS') {
      return true
    }
    if (!name || name.trim().length === 0) {
      warningToast('Please enter function name')
      return false
    }
    if (name === 'response' || name === 'context') {
      warningToast(`Invalid name! '${name}' is a reserved keyword`)
      return false
    }

    if (name.length < minNameLength) {
      warningToast(`${type} name should contain at least ${minNameLength} characters`)
      return false
    }
    if (name.length > maxNameLength) {
      warningToast(`${type} name should contain at most ${maxNameLength} characters`)
      return false
    }

    if (!pattern.test(name)) {
      warningToast(`${type} name can only contain alphabets, numbers, and underscores [a-z A-Z 0-9 _]`)
      return false
    }

    if ((type === BlockTypes.VARIABLE || type === BlockTypes.IFBLOCK) && !code) {
      warningToast(`${type} data can't be empty.`)
      return false
    }

    if (name[0] >= '0' && name[0] <= '9') {
      warningToast('Function name cannot start with a numeric value')
      return false
    }

    let isNameExists = false
    Object.entries(flowJson.blocks).forEach(([key, value]) => {
      if (key === name && value?.identifier !== id) isNameExists = true
    })
    if (isNameExists) {
      warningToast('Function name already exists')
      return false
    }

    if (type === BlockTypes.API && !code?.url) {
      warningToast('Url Required')
      return false
    }

    if (
      code?.serviceId &&
      code?.selectedCreate &&
      code?.actionAuthType &&
      whiteListDomainsForCustomPluginApi &&
      whiteListDomainsForCustomPluginApi !== ''
    ) {
      //  this if is for custom plugin

      const domainNameToCompare = extractDomainWithoutWWW(code.url)
      if (!whiteListDomainsForCustomPluginApi?.some((item) => item === domainNameToCompare)) {
        errorToast('Please add url from whitelisted url')
        return false
      }
    }
    if (ReservedKeywords.includes(name?.toLowerCase())) {
      errorToast('title cannot be any reserved keyword')
      return false
    }
    return true
  }
  const isDisabled =
    isPublishedTab ||
    loading ||
    (type !== BlockTypes.RESPONSE &&
      !hasUnsavedCode &&
      !hasToBeDisabled &&
      flowJson?.blocks?.[slugName]?.status !== STEP_OPERATION_STATUS.DRAFTED)
  return (
    tabName !== Tabnames.PUBLISH && (
      <Box
        className='saveButtonV3Class pos-abs bottom-0 z-index-98 fill-available-width w-100'
        sx={{ backgroundColor: isPublishedTab ? '#fffdf4' : '#f5f5f4' }}
      >
        <Divider />
        <Button
          className='m-3'
          disabled={isDisabled}
          startIcon={loading && <CircularProgress size={18} />}
          variant='contained'
          onClick={async () => {
            try {
              setLoading(true)
              if (type === 'function' && stepInstance?.variablesChanged) {
                await dispatch(askAiForFunctionThunk({ purpose: 'variable update' })).then((e) => {
                  currentCodeRef.current = e?.payload?.code
                  saveFunction()
                })
              } else await saveFunction()
            } catch (error) {
              // nothing to perform
            } finally {
              setLoading(false)
            }
          }}
        >
          {flowJson?.blocks?.[slugName]?.status === STEP_OPERATION_STATUS.DELETE ? 'Undelete and Save' : 'Done'}
        </Button>
      </Box>
    )
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(SaveButtonV3), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.stepId,
    ParamsEnums.slugName,
    ParamsEnums.tabName
  ])
)

const convertToArrayObject = (type, data) => {
  return data?.length !== 0 ? getStringToArrayObject(type, data) : []
}

export const createCodeForAPI = (stepInstance) => {
  let customHeaders = ''
  let customQueryParams = ''
  if (stepInstance?.pluginData?.queryParamsAndHeaders) {
    const headersAndQueryParams = stepInstance?.pluginData?.queryParamsAndHeaders
    customHeaders = headersAndQueryParams?.headers
    customQueryParams = headersAndQueryParams?.queryParams
  }
  const headersToSend = convertToArrayObject(2, stepInstance?.headers)
  const queryParamsToSend = convertToArrayObject(1, stepInstance?.queryParams)
  return {
    url: urlWithParams(stepInstance?.url || '').url,
    headers: headersToSend,
    queryString: queryParamsToSend,
    method: stepInstance?.httpRequestType?.toUpperCase() || 'GET',
    postData: getPostDataForApiPayload(stepInstance, true),
    pluginData: stepInstance?.pluginData,
    oauth: stepInstance?.pluginData?.selectedValues?.authData,
    selectedValues: stepInstance?.pluginFieldsInputData?.selectedValues,
    iconUrl: stepInstance?.iconUrl,
    customHeaders: customHeaders,
    customQueryParams: customQueryParams
  }
}
