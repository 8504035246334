import Dialog from '@mui/material/Dialog'
import { useDispatch } from 'react-redux'
import { closeModalRedux } from '../../store/appInfo/appInfoSlice.ts'

export default function CustomModal(props) {
  const dispatch = useDispatch()
  const handleKeyPress = () => {
    if (props?.onClose) {
      props?.onClose()
    } else {
      dispatch(closeModalRedux())
    }
  }

  return (
    <Dialog
      fullScreen={props?.fullScreen}
      PaperProps={{ style: { width: props?.width || '50vw', maxWidth: 'unset !important' } }}
      open={props?.openModal}
      onClose={handleKeyPress}
    >
      {props?.children}
    </Dialog>
  )
}
