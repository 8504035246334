import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, ListItemIcon, MenuItem, TextField, Typography } from '@mui/material'
import { Logout } from '@mui/icons-material'
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { selectActiveOrgs } from '../../store/orgs/orgsSelector'
import { fetchOrgs, renameOrgThunk } from '../../store/orgs/orgsThunk'
import { closeModalRedux, openModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from '../../enums'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { getCurrentEnvironment, removeCookie, validateOrgName } from '../../utils/utilities'
import AllOrgs from './AllOrgs.tsx'
import { logoutUserFromMsg91 } from '../../api/index'
import selectActiveUser from '../../store/user/userSelector'
import CreateOrgModal from '../../pages/formbilling/createOrgModal.tsx'
import AdaptiveComponentForModalAndPage from '../AdaptiveComponentForModalAndPage/AdaptiveComponentForModalAndPage.tsx'

function WorkspacesComponent({ isModal = false, orgId = '' }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userDetails, orgList, orgObj } = useCustomSelector((state: $ReduxCoreType) => ({
    userDetails: selectActiveUser(state),
    orgList: selectActiveOrgs(state)?.sort((a, b) => a?.name?.localeCompare(b?.name)) || [],
    orgObj: state?.orgs?.orgs?.[orgId]
  }))
  const orgIcon = orgObj?.meta?.iconUrl

  useEffect(() => {
    const handleEscKeyPress = (event) => {
      if (event.key === 'Escape') {
        dispatch(closeModalRedux())
      }
    }

    document.addEventListener('keydown', handleEscKeyPress)

    return () => {
      document.removeEventListener('keydown', handleEscKeyPress)
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchOrgs())
  }, [])

  const [newTitle, setNewTitle] = useState('')
  // const [showTextField, setShowTextField] = useState('-1')
  const [orgListVariable, setOrgListVariable] = useState(orgList || [])

  useEffect(() => setOrgListVariable(orgList), [orgList])
  const addNewWorkspace = () => {
    dispatch(openModalRedux({ modalType: 'createOrg' }))
  }
  const handleLogOut = async () => {
    try {
      await logoutUserFromMsg91()
      removeCookie(getCurrentEnvironment())
      localStorage.clear()
      sessionStorage.clear()
      if (process.env.REACT_APP_API_ENVIRONMENT === 'prod') window.location.replace(process.env.REACT_APP_VIASOCKET_HOME_PAGE_URL)
      else navigate('/')
    } catch (e) {
      console.error(e)
    }
  }

  const handleTextFieldClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setNewTitle(e.target.value)
  }

  const inputFieldHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleRenameOnClick = (org, value) => {
    if (!validateOrgName(value, orgList, setOrgListVariable)) return
    const orgId = org.id
    dispatch(renameOrgThunk({ body: { title: value }, orgId }))
  }

  const selectedName = (
    <Box className='flex-start-center gap-2 text-truncate'>
      <IconWrapper
        size='24px'
        iconUrl={orgIcon}
        component={
          orgIcon ? (
            <img src={orgIcon} alt='icon' width='100%' />
          ) : (
            <Avatar variant='square'>
              <Typography className=' flex item-center justify-center text-right pl-2' variant='bold'>
                {orgObj?.name?.toUpperCase()?.substring(0, 2) || ''}
              </Typography>
            </Avatar>
          )
        }
      />
      <Typography variant='bold' className='ml-2'>
        {orgObj?.name || ''}
      </Typography>
    </Box>
  )

  const returnComponentForRenamingScript = (org) => {
    return (
      <Box
        key={org.id}
        className={`${isModal ? 'workspace__modal__element' : 'workspace__element '} ${
          orgId === org.id ? 'selected_org' : ''
        } flex-spaceBetween-center w-100 box-sizing-border-box `}
      >
        <Box className='flex-start-center w-100 gap-3'>
          <Avatar variant='rounded'>
            <Typography variant='bold'>{org?.name?.toUpperCase()?.substring(0, 2) || ''}</Typography>
          </Avatar>
          <TextField
            inputProps={{ maxLength: 25 }}
            size='small'
            noborder='true'
            autoFocus
            fullWidth
            className='title-textfield'
            scriptname='true'
            onBlur={() => {
              // setShowTextField('-1')
              handleRenameOnClick(org, newTitle)
            }}
            id='outlined-helperText'
            defaultValue={org.name}
            onChange={handleTextFieldClick}
            onClick={inputFieldHandler}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // setShowTextField('-1')
                handleRenameOnClick(org, newTitle)
              }
            }}
          />
        </Box>
      </Box>
    )
  }

  return (
    <>
      <AdaptiveComponentForModalAndPage
        selectedName={selectedName}
        subheading={userDetails.email}
        isModal={isModal}
        closeOnChangeOf={orgId}
        heading='Select a WorkSpace'
        body={
          <AllOrgs
            orgListVariable={orgListVariable}
            returnComponentForRenamingScript={returnComponentForRenamingScript}
            isModal={isModal}
          />
        }
        footer={
          <Box>
            <MenuItem onClick={addNewWorkspace}>
              <ListItemIcon>
                <LibraryAddOutlinedIcon fontSize='small' />
              </ListItemIcon>
              <Box>Create new workspace</Box>
            </MenuItem>
            <MenuItem onClick={handleLogOut}>
              <ListItemIcon>
                <Logout fontSize='small' />
              </ListItemIcon>
              <Box>Logout</Box>
            </MenuItem>
          </Box>
        }
      />
      <CreateOrgModal />
    </>
  )
}
export default React.memo(addUrlDataHoc(React.memo(WorkspacesComponent), [ParamsEnums.orgId]))
