import React, { useEffect } from 'react'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { getInfoParamtersFromUrl } from '../../utils/utilities'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { $ReduxCoreType } from '../../types/reduxCore.ts'

function CheckOrgBillingStatus() {
  const { orgId } = getInfoParamtersFromUrl()
  const { orgData } = useCustomSelector((state: $ReduxCoreType) => ({
    orgData: state.orgs?.orgs[orgId]
  }))
  useEffect(() => {
    if (orgId && orgData) {
      if (orgData?.meta?.org_status === undefined && !window.location.pathname.startsWith('/activate'))
        window.location.href = `/activate/${orgId}`
      if (orgData?.meta?.org_status && window.location.pathname.startsWith('/activate') && !window.location.pathname.endsWith('success'))
        window.location.href = `/projects/${orgId}`
    }
  }, [orgData?.meta?.org_status, orgId])
  return null
}
export default React.memo(addUrlDataHoc(React.memo(CheckOrgBillingStatus), []))
