import { Box, Chip, Skeleton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ALERTTYPES, ParamsEnums } from '../../enums'
import { RenderIcons } from '../../utils/utilities'
import { getTemplatesTriggerWise } from '../../api/index'
import { sendAlert } from '../../utils/alertUtility.ts'

function Templates({ eventId = '', handleTemplateClick, filter = '', blankflowButton = false }) {
  const [templates, setTemplates] = useState(null)
  useEffect(() => {
    fetchTemplates()
  }, [eventId])
  const fetchTemplates = async () => {
    try {
      setTemplates(null)
      const data = await getTemplatesTriggerWise(eventId, filter)
      setTemplates(data?.data?.data || [])
    } catch (error) {
      sendAlert(error, ALERTTYPES.FRONTEND, 'getTemplatesTriggerWise api alert')
      setTemplates(null)
    }
  }
  return Array.isArray(templates) && (templates?.length || blankflowButton) ? (
    <Box className='flex-start-center gap-2 w-100 flex-wrap p-2'>
      <Typography variant='smallHeading' className='w-100 ml-2'>
        Templates
      </Typography>
      {blankflowButton && (
        <Box className='templates_box p-2 flex-col-spaceBetween-start' onClick={() => handleTemplateClick()}>
          <Box className='column gap-3'>
            <Typography variant='h6'>Start from scratch</Typography>
            <Typography>Create a blank flow explore APIs, functions and rich variety of services.</Typography>
          </Box>
        </Box>
      )}
      {templates?.map((template) => {
        return (
          <Box
            className='templates_box p-2 flex-col-spaceBetween-start'
            onClick={() => handleTemplateClick(template)}
            key={template?.template_id}
          >
            <Box className='column gap-3'>
              <RenderIcons flowJsonBlocks={template?.published_json_script?.blocks} size='24px' alternate='' />

              {template?.description}
            </Box>
            <Chip variant='outlined' label='Use it' />
          </Box>
        )
      })}
    </Box>
  ) : (
    !Array.isArray(templates) && (
      <Box className='flex-start-center gap-2 w-100 flex-wrap p-2 pt-3'>
        {Array.from({ length: 4 }, (_, index) => index).map((el) => {
          return <Skeleton height='300px' key={el} variant='rectangular' width='320px' />
        })}
      </Box>
    )
  )
}
export default React.memo(addUrlDataHoc(React.memo(Templates), [ParamsEnums.projectId, ParamsEnums.orgId]))
