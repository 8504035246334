import defaultAxios from 'axios'
import { buildKeyGenerator, setupCache } from 'axios-cache-interceptor'
import { store } from '../store'
import {
  getFromCookies,
  setInCookies,
  getInfoParamtersFromUrl,
  // getSubdomain,
  removeCookie,
  getCurrentEnvironment
} from '../utils/utilities'
import { intefaceGetLocalStorage } from '../pages/interface/utils/InterfaceUtils.ts'
// import { MiscTypes } from '../enums'
import { errorToast } from '../components/customToast'
import { MiscTypes } from '../enums'

const WHITELISTED_URL = []

const instance = defaultAxios.create()
const axios = setupCache(instance, {
  ttl: 1000 * 60 * 5, // 5 minute.
  methods: ['get'],
  generateKey: buildKeyGenerator((request) => ({
    method: request.method,
    url: request.url
  }))
})

// const urlParams = new URLSearchParams(window.location.search)

// const headerKey = (key) => {
//   let head = key === 'accessToken' ? 'Authorization' : key
//   head =
//     process.env.REACT_APP_API_ENVIRONMENT !== 'local' &&
//     process.env.REACT_APP_AUTH_URL === `https://${getSubdomain()}` &&
//     (urlParams.get('mode') === MiscTypes.EMBED_MODE || urlParams.get('state'))
//       ? 'Authorization'
//       : head
//   return head
// }
// request interceptor
axios.interceptors.request.use(
  async (config) => {
    let key
    if (process.env.REACT_APP_API_ENVIRONMENT === 'local') {
      config.headers['proxy_auth_token'] = localStorage.getItem('proxy_auth_token')
      key = 'Authorization'
    } else key = 'proxy_auth_token'
    if (intefaceGetLocalStorage('interfaceToken')) {
      key = 'Authorization'
    }
    let token = ''
    const urlParams = new URLSearchParams(window.location.search)
    const modeInQueryParams = urlParams.get('mode')

    const { mode } = {
      mode: modeInQueryParams || store.getState()?.appInfo?.mode
    }

    token =
      mode === MiscTypes.FLOW || mode === MiscTypes.OAUTH_MODE
        ? getFromCookies(getCurrentEnvironment())
        : sessionStorage.getItem('ssoEmbedToken')
    if (token) {
      if (mode !== 'flow' && process.env.REACT_APP_API_ENVIRONMENT === 'local') {
        config.headers['proxy_auth_token'] = localStorage.getItem('proxy_auth_token')
      }
      if (intefaceGetLocalStorage('interfaceToken')) {
        key = 'Authorization'
        token = intefaceGetLocalStorage('interfaceToken')
        config.headers[key] = token
      } else config.headers[key] = token
    } else if (intefaceGetLocalStorage('interfaceToken')) {
      key = 'Authorization'
      token = intefaceGetLocalStorage('interfaceToken')
      config.headers[key] = token
    }
    if (
      WHITELISTED_URL.some((urlToWhilteList) => {
        return !!config.url.match(urlToWhilteList)
      })
    ) {
      config.cache = {}
    } else {
      config.cache = false
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)
// response interceptor
axios.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (error?.response?.status === 401) {
      removeCookie(getCurrentEnvironment())
      localStorage.clear()
      const params = new URLSearchParams(window.location.search)
      // Step 2: Remove the parameters for slider handling
      params.delete('stepId')
      params.delete('slugName')
      const remainingSearchString = params.toString()
      const previousUrlData = {
        ...getInfoParamtersFromUrl(),
        previous_url: `${window.location.pathname}${remainingSearchString ? `?${remainingSearchString}` : ''}`
      }
      setInCookies(`${getCurrentEnvironment()}-previous_url`, JSON.stringify(previousUrlData))

      let redirectUrl
      if (window.location.origin === process.env.REACT_APP_EMBED_URL)
        redirectUrl = new URL(`${process.env.REACT_APP_EMBED_URL}/embed/error`)
      else if (process.env.REACT_APP_API_ENVIRONMENT === 'prod') {
        redirectUrl = new URL(process.env.REACT_APP_VIASOCKET_HOME_PAGE_URL)
      } else {
        redirectUrl = new URL(window.location.origin)
      }

      redirectUrl.searchParams.append('error', 'session expired')

      window.location.href = redirectUrl.href
    }
    if (error?.response?.status >= 500) {
      errorToast('Server Error, Try again later')
    }

    return Promise.reject(error)
  }
)

export default axios
