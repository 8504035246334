import { useDispatch } from 'react-redux'
import React from 'react'
import { Box, Button } from '@mui/material'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import './scriptListV2.scss'
import { ParamsEnums } from '../../enums'
import CustomModal from '../customModal/customModal'
import AppsExplore from '../AppsExplore/AppsExplore.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'

interface CreateNewPopoverProps {
  projectId?: string
  defaultProjectId?: string
}

/**
 * CreateNewPopover component for creating new items in a project dashboard.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.projectId - The ID of the current project.
 * @param {string} props.defaultProjectId - The default project ID to use if projectId is not provided.
 * @returns {JSX.Element} The rendered CreateNewPopover component.
 */

function CreateNewPopover({ projectId, defaultProjectId }: CreateNewPopoverProps) {
  const dispatch = useDispatch()
  const { modalType } = useCustomSelector((state) => ({
    modalType: state.appInfo.modalType
  }))
  const finalProjectId = defaultProjectId || projectId
  const handleClick = () => {
    dispatch(updateAppInfoState({ modalType: `appsExplore_${finalProjectId}` }))
  }
  return (
    <Box>
      <Button variant='contained' color='success' className='m-2' onClick={handleClick}>
        New
      </Button>
      <CustomModal openModal={modalType === `appsExplore_${finalProjectId}`} width='1100px'>
        <AppsExplore finalProjectId={finalProjectId} />
      </CustomModal>
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(CreateNewPopover), [ParamsEnums.orgId, ParamsEnums.projectId]))
